import styled from 'styled-components';

import { screen } from '@/helpers/screen';

//components
import { ButtonHero, MirandaButton } from '@/components/Button';
import { Container } from 'components/Grid';
import { Title, Description } from '@/containers/Hero/Hero.styles';

//icon
import CarrierTMS from '@/public/images/carrier-tms/hero/icon_logo-carriertms.svg';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

export const HeroWrapper = styled.header`
  width: 100%;
  min-height: 540px;

  background-color: #202931;
  border-bottom: 11px solid transparent;
  border-image: linear-gradient(45.01deg, #00d7d7 23.29%, #84f11e 88.12%);
  border-image-slice: 1;
  box-sizing: content-box;

  svg {
    width: 122px;
    height: auto;
  }

  ${screen.md} {
    min-height: 575px;

    svg {
      width: 190px;
      height: auto;
    }
  }

  ${screen.lg} {
    min-height: 645px;
  }

  ${screen.xxlg} {
    min-height: 770px;
  }
`;

export const HeroContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 60px;

  ${screen.md} {
    max-width: 733px;
  }

  ${screen.lg} {
    max-width: 1034px;
    padding-top: 80px;
  }

  ${screen.xxlg} {
    max-width: 1358px;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  ${screen.md} {
    flex-direction: row;
  }
`;

export const ButtonStyled = styled(MirandaButton)`
  font-family: ${getToken('font-family-default')};
  color: rgb(${getToken('color-text-primary')});
  font-weight: ${getToken('font-weight-bold')};
  background: linear-gradient(45.01deg, #00d7d7 23.29%, #84f11e 88.12%);
  padding: 10px 23px;
  font-size: 12px;
  line-height: 12px;

  margin: 0px 0px 10px;

  &:hover,
  &:active {
    background: linear-gradient(45.01deg, #00acac 23.29%, #69cc0c 88.12%);
  }

  ${screen.md} {
    padding: 10px 16px;
    margin: 0px 24px 0px 0px;
  }

  ${screen.lg} {
    font-size: 16px;
    line-height: 16px;
    padding: 18px 16px;
  }
`;

export const GhostButtonStyled = styled(MirandaButton)`
  font-family: ${getToken('font-family-default')};
  font-weight: ${getToken('font-weight-bold')};
  color: rgb(${getToken('color-text-inverted')});
  background-color: transparent;
  border: 2px solid rgb(${getToken('color-background-primary')});
  padding: 8px 16px;
  font-size: 12px;
  line-height: 12px;

  &:hover,
  &:active {
    color: rgb(${getToken('color-text-primary')});
    background: rgb(${getToken('color-background-tertiary')});
    border-color: rgb(${getToken('color-background-inverted')});
  }

  &:focus {
    outline: 1px solid rgb(${getToken('color-background-inverted')});
  }

  ${screen.lg} {
    padding: 16px;
    font-size: 16px;
    line-height: 16px;
  }
`;

export const CarrierTMSLogo = styled(CarrierTMS)`
  width: 100%;
  height: 100%;
`;

export const StyledTitle = styled(Title)`
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-inverted')});
  font-weight: ${getToken('font-weight-bold')};

  margin: 24px 0 0;

  font-size: 24px;
  line-height: 36px;

  text-align: center;

  ${screen.md} {
    font-size: 26px;
    line-height: 39px;

    br:nth-child(2) {
      display: none;
    }
  }

  ${screen.lg} {
    font-size: 48px;
    line-height: 60px;
  }

  ${screen.xxlg} {
    font-size: 58px;
    line-height: 72px;
  }
`;

export const StyledDescription = styled(Description)`
  font-family: ${getToken('platform-font-title')};
  color: rgb(${getToken('color-text-inverted')});
  font-weight: ${getToken('font-weight-regular')};
  margin: 24px 0 34px;
  font-size: 14px;
  line-height: 22px;
  text-align: center;

  br {
    display: none;
  }

  ${screen.md} {
    font-size: 16px;
    line-height: 24px;
    margin: 16px 0 26px;

    br {
      display: inline-block;
    }
  }

  ${screen.lg} {
    margin: 24px 0;
  }

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 30px;

    br {
      display: none;
    }
  }
`;
