import styled from 'styled-components';

//miranda tokens
import { getToken } from '@loadsmart/miranda-tokens';

//
import { screen } from '@/helpers/screen';
import conditional from '@/helpers/conditional';

//
import { Wrapper as BaseWrapper } from '@/components/Grid/Content';

export const SidePanelsWrapper = styled.section`
  padding: 40px 0 80px;

  text-align: center;
`;

export const SectionTitle = styled.h1`
  margin: 20px auto;
  padding: 0 40px;

  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  text-align: center;

  ${screen.md} {
    margin: 32px auto;
    padding: 0;

    font-size: 28px;
    line-height: 40px;
  }
  ${screen.lg} {
    font-size: 38px;
    line-height: 50px;
  }

  ${screen.xxlg} {
    margin: 40px auto;

    font-size: 48px;
    line-height: 72px;
  }
`;

export const SectionIntro = styled.p`
  margin: 20px auto;
  padding: 0 20px;

  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;

  ${screen.md} {
    margin: 32px auto;

    font-size: 16px;
    line-height: 24px;
  }
  ${screen.lg} {
    font-size: 21px;
    line-height: 34px;
  }

  ${screen.xxlg} {
    margin: 40px auto;

    font-size: 26px;
    line-height: 38px;
  }
`;

export const Title = styled.h2`
  margin-bottom: 10px;

  font-weight: 700;
  font-size: 25px;
  line-height: 36px;
  text-align: center;

  ${screen.md} {
    font-size: 28px;
    line-height: 40px;
    text-align: left;
  }
  ${screen.lg} {
    font-size: 38px;
    line-height: 50px;
  }

  ${screen.xxlg} {
    font-size: 40px;
    line-height: 56px;
  }
`;

export const Subtitle = styled.h3`
  margin: 0;

  color: #787b7d;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;

  ${screen.md} {
    line-height: 26px;
    text-align: left;
  }

  ${screen.lg} {
    font-size: 22px;
    line-height: 36px;
  }

  ${screen.xxlg} {
    font-size: 28px;
    line-height: 44px;
  }
`;

export const Content = styled.div`
  margin: 16px 0;

  font-size: 12px;
  line-height: 22px;

  ul {
    li {
      font-weight: 700;

      list-style: disc inside;
    }
  }

  ${screen.md} {
    margin: 22px 0;
  }

  ${screen.lg} {
    margin: 36px 0;

    font-size: 16px;
    line-height: 30px;
  }

  ${screen.xxlg} {
    margin: 42px 0;

    font-size: 20px;
    line-height: 38px;
  }
`;

export const Link = styled.a`
  display: inline-flex;
  width: fit-content;
  margin: auto;

  color: ${({ theme }) => theme.color.greens.stronger};
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-decoration: underline;

  ${screen.md} {
    margin: 0;

    font-size: 15px;
    line-height: 24px;
  }

  ${screen.lg} {
    font-size: 20px;
    line-height: 28px;
  }

  ${screen.xxlg} {
    font-size: 24px;
    line-height: 32px;
  }

  &:hover {
    color: ${({ theme }) => theme.color.primaryCodeString};
  }
`;

export const Picture = styled.picture`
  display: inline-grid;
  box-sizing: border-box;

  ${screen.md} {
    padding: ${conditional({
      0: (props) => props.direction !== 'reverse',
      '0 20px 0 0': (props) => props.direction === 'reverse',
    })};
  }

  img,
  source {
    width: 100%;
    max-width: 100%;
    height: auto;
    margin: auto;
  }
`;

export const SectionPanel = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 0 20px;

  ${screen.md} {
    flex-direction: ${conditional({
      'row-reverse': (props) => props.direction !== 'reverse',
      row: (props) => props.direction === 'reverse',
    })};
    padding: 50px 0;
  }
`;

export const Wrapper = styled(BaseWrapper)`
  justify-content: center;
  padding: 0;

  ${screen.md} {
    align-items: flex-start;
    width: 326px;

    padding: ${conditional({
      '0 14px 0 0': (props) => props.direction !== 'reverse',
      '0 0 0 14px': (props) => props.direction === 'reverse',
    })};

    text-align: left;
  }

  ${screen.lg} {
    width: auto;
  }

  ${screen.xxlg} {
    padding: ${conditional({
      '0 22px 0 0': (props) => props.direction !== 'reverse',
      '0 0 0 22px': (props) => props.direction === 'reverse',
    })};
  }
`;

export const BrandWrapper = styled.div`
  display: flex;
  width: fit-content;
  margin: auto;
  color: rgb(${getToken('color-primary-100')});
  font-family: ${getToken('font-family-title')};
  font-weight: ${getToken('font-weight-regular')};

  font-size: 27px;

  ${screen.md} {
    font-size: 32.4px;
  }

  ${screen.lg} {
    font-size: 44px;
  }
`;

export const LogoWrapper = styled.div`
  height: 25px;
  margin-bottom: 24px;

  ${screen.md} {
    height: 30px;
  }

  ${screen.lg} {
    height: 41px;
    margin-bottom: 36px;
  }

  svg,
  path {
    height: 100%;
    color: rgb(${getToken('color-primary-100')});
    fill: rgb(${getToken('color-primary-100')});
  }
`;
