import React from 'react';

// styles
import {
  HeroWrapper,
  HeroContainer,
  GhostButtonStyled,
  ButtonStyled,
  FlexRow,
  CarrierTMSLogo,
  StyledTitle,
  StyledDescription,
} from './Hero.styles';

export function Hero() {
  return (
    <HeroWrapper>
      <HeroContainer>
        <CarrierTMSLogo />
        <StyledTitle>
          Optimize dispatch. Reduce overhead costs.
          <br />
          Scale trucking fleets.
          <br /> All in one TMS.
        </StyledTitle>
        <StyledDescription>
          A truck management software that has all your carrier operations in
          one place,
          <br /> making it easier to manage all areas within your business.
        </StyledDescription>
        <FlexRow>
          <ButtonStyled
            as="a"
            href="https://tms.kamion.io/login?state=checkAccount&__hstc=32328097.74da73e6aea128bbf7b33d90935d2139.1631884434263.1677597267980.1677600269152.372&__hssc=32328097.1.1677600269152&__hsfp=1404585697"
          >
            Free Carrier TMS
          </ButtonStyled>
          <GhostButtonStyled
            as="a"
            href="https://tms.kamion.io/login?__hstc=32328097.74da73e6aea128bbf7b33d90935d2139.1631884434263.1677706384608.1677789873327.377&__hssc=32328097.10.1677789873327&__hsfp=2164298326"
          >
            Login: Carrier TMS
          </GhostButtonStyled>
        </FlexRow>
      </HeroContainer>
    </HeroWrapper>
  );
}
