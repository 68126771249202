//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '/containers-m/';
import { BannerStrip } from '@/containers-m/BannerStrip';

//context containers
import {
  Hero,
  Services,
  IntroductionText,
  CustomersLogos,
  Featured,
  SidePanels,
  LifeLoadVideo,
  ShipWithUs,
  Newsroom,
  ImageCTA,
  LogoImageCTA,
} from './containers/index';

import { HubspotFormSimplified } from '@/containers-m/HubspotFormSimplified';

//styles
import { PageWrapper, HubspotFormWrapper } from './Home.styles';

//vendor
import { defaultOptions } from '@/vendor/HubspotFormIntegration';

const form_content = {
  title: (
    <>
      Lower costs, increase efficiency, and digitize transportation with
      Loadsmart’s solutions
    </>
  ),
  subtitle: (
    <>
      Lower freight costs and boost performance with Loadsmart’s software and
      services.
    </>
  ),
};

const bannerContent = (
  <p>
    Ship any load, any mode, anywhere. <br />{' '}
    <a
      href={`${process.env.SHIPPERGUIDE_URL}/signup?utm_source=loadsmart&utm_medium=banner&utm_campaign=free-account-creation-midmarket-shipperguide-q1-23&utm_content=quoting-bar-home-page`}
      target="_blank"
      rel="noopener noreferrer"
    >
      Sign up now
    </a>{' '}
    for free and get <br /> shipping quotes instantly.
  </p>
);

function Home() {
  return (
    <PageWrapper>
      <Navigation theme="dark" />
      <>
        <BannerStrip content={bannerContent} />
        <Hero />
        <CustomersLogos />
        <Services />
        <IntroductionText />
        <ImageCTA />
        <LogoImageCTA />
        <SidePanels />
        <Featured />
        <LifeLoadVideo />
        <Newsroom />
        <ShipWithUs />
        <HubspotFormWrapper id="contact-form">
          <HubspotFormSimplified
            content={form_content}
            {...defaultOptions}
            disclaimer={true}
            theme="dark"
          />
        </HubspotFormWrapper>
      </>
      <Footer />
    </PageWrapper>
  );
}

export default Home;
export { Home };
