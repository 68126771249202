/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */

// styles
import {
  Container,
  SquaresMenuWrapper,
  Title,
  Content,
  SegmentNavigationWrapper,
  NavList,
  ItemMenu,
  Image,
  Label,
} from './SquaresMenu.styles';

const items = [
  {
    title: 'FTL',
    title_mobile: 'FULL TRUCKLOAD',
    link: {
      url: '/ftl/',
      external: false,
    },
    image: {
      files: {
        sm: '/images/shipper/squares-nav/menu-1-ftl-sm.png',
        md: '/images/shipper/squares-nav/menu-1-ftl-md.jpg',
        lg: '/images/shipper/squares-nav/menu-1-ftl-lg.jpg',
        xxlg: '/images/shipper/squares-nav/menu-1-ftl-xxlg.jpg',
      },
      alt: 'FTL Truck',
    },
  },
  {
    title: 'LTL',
    title_mobile: 'LESS THAN TRUCKLOAD',
    link: {
      url: 'https://lp.loadsmart.com/ltl-less-than-truckload-from-loadsmart',
      external: true,
    },
    image: {
      files: {
        sm: '/images/shipper/squares-nav/menu-2-ltl-sm.png',
        md: '/images/shipper/squares-nav/menu-2-ltl-md.jpg',
        lg: '/images/shipper/squares-nav/menu-2-ltl-lg.jpg',
        xxlg: '/images/shipper/squares-nav/menu-2-ltl-xxlg.jpg',
      },
      alt: 'LTL on warehouse',
    },
  },
  {
    title: 'EXPEDITED SHIPPING',
    title_mobile: 'EXPEDITED SHIPPING',
    link: {
      url: '/shipper/expedited-shipping/',
      external: false,
    },
    image: {
      files: {
        sm: '/images/shipper/squares-nav/menu-3-expedited-shipping-sm.png',
        md: '/images/shipper/squares-nav/menu-3-expedited-shipping-md.jpg',
        lg: '/images/shipper/squares-nav/menu-3-expedited-shipping-lg.jpg',
        xxlg: '/images/shipper/squares-nav/menu-3-expedited-shipping-xxlg.jpg',
      },
      alt: 'SPRINTER VAN',
    },
  },
  {
    title: 'PORT DRAYAGE',
    title_mobile: 'PORT DRAYAGE',
    link: {
      url: '/port-drayage/',
      external: false,
    },
    image: {
      files: {
        sm: '/images/shipper/squares-nav/menu-4-port-drayage-sm.png',
        md: '/images/shipper/squares-nav/menu-4-port-drayage-md.jpg',
        lg: '/images/shipper/squares-nav/menu-4-port-drayage-lg.jpg',
        xxlg: '/images/shipper/squares-nav/menu-4-port-drayage-xxlg.jpg',
      },
      alt: 'Port load',
    },
  },
  {
    title: 'MODE OPTIMIZATION',
    title_mobile: 'MODE OPTIMIZATION',
    link: {
      url: '/mode-optimization/',
      external: false,
    },
    image: {
      files: {
        sm: '/images/shipper/squares-nav/menu-5-mode-optimization-sm.png',
        md: '/images/shipper/squares-nav/menu-5-mode-optimization-md.jpg',
        lg: '/images/shipper/squares-nav/menu-5-mode-optimization-lg.jpg',
        xxlg: '/images/shipper/squares-nav/menu-5-mode-optimization-xxlg.jpg',
      },
      alt: 'Train mode',
    },
  },
];

export function SquaresMenu() {
  return (
    <SquaresMenuWrapper>
      <Container>
        <Title>All shipping modes optimized for your needs.</Title>
        <Content>
          Access instant, dynamic capacity to move your overland <br /> freight
          — no matter the mode or market.
        </Content>
      </Container>

      <SegmentNavigationWrapper>
        <Container>
          <NavList>
            {items.map((item, key) => {
              return (
                <ItemMenu
                  href={item.link.url}
                  key={key + item.title.trim()}
                  target={item.link.external ? `_blank` : `_self`}
                  rel={item.link.external ? `noreferrer noopener` : ``}
                >
                  <Image>
                    <source
                      srcSet={item.image.files.xxlg}
                      media="(min-width: 1920px)"
                    />
                    <source
                      srcSet={item.image.files.lg}
                      media="(min-width: 1024px)"
                    />
                    <source
                      srcSet={item.image.files.md}
                      media="(min-width: 768px)"
                    />
                    <img
                      width="325"
                      height="130"
                      src={item.image.files.sm}
                      alt={item.image.alt}
                    />
                  </Image>
                  <Label>{item.title}</Label>
                  <Label className="mobile">{item.title_mobile}</Label>
                </ItemMenu>
              );
            })}
          </NavList>
        </Container>
      </SegmentNavigationWrapper>
    </SquaresMenuWrapper>
  );
}
