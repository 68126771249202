import React, { useState } from 'react';
import { useRouter } from 'next/router';

//styles
import {
  FeaturesNavBannerWrapper,
  StyledContainer,
  Content,
  Title,
  Text,
  SmallText,
  StyledButton,
  FeaturesNavBannerNav,
  FeaturesNavBannerNavWrapper,
  FeaturesNavBannerNavTitle,
  FeaturesNavBannerNavGroup,
  FeaturesNavBannerNavItem,
  MobileNav,
  MobileNavWrapper,
  MobileNavTitle,
} from './FeaturesNavBanner.styles';

const features_finance = [
  {
    title: <>Invoicing</>,
    anchor: 'invoicing',
    copy: 'With all your documentation in one place, Carrier TMS is the logical location to create and submit invoices. Sync invoices to Quickbooks to simplify bookkeeping.',
    image: '/images/features/screenshots/fa-01_Invoicing.png',
  },
  {
    title: <>Driver Statements</>,
    anchor: 'driver-statements',
    copy: 'Create and customize driver statements based on mileage, time, or percentage of load price.',
    image: '/images/features/screenshots/fa-02_Driver-Statements.png',
  },
  {
    title: <>Quickbooks</>,
    anchor: 'quickbooks',
    copy: 'Sync invoice information and data from Carrier TMS to Quickbooks at the click of a button, saving the need to spend hours copying and pasting from one system to another.',
    image: '/images/features/screenshots/fa-03_Quickbooks.png',
  },
  {
    title: <>Factoring</>,
    anchor: 'factoring',
    copy: 'Easily click-to-send invoices to RTS Financial, or use our own 2.5% factoring for same-day payments.',
    image: '/images/features/screenshots/fa-04_Factoring',
  },
  {
    title: <>1099</>,
    anchor: '1099-reports',
    copy: '1099 reports used to be a once-a-year hassle for your contract-based drivers, that is now an easy task thanks to integrations and reporting capabilities within Carrier TMS.',
    image: '/images/features/screenshots/fa-05_Reporting.png',
  },
  {
    title: <>IFTA</>,
    anchor: 'ifta-reports',
    copy: 'Fuel Card integrations with ELD integrations make IFTA reporting a breeze.',
    image: '/images/features/screenshots/fa-06_IFTA-Reports.png',
  },
];

const back_office_management = [
  {
    title: <>Document & Safety Management</>,
    anchor: 'document-&-safety-management',
    copy: 'Stay compliant with simplified document management including expiration dates and alerts.',
    image:
      '/images/features/screenshots/ma-01_Document-&-Safety-Management.png',
  },
  {
    title: <>Multiple MC Management</>,
    anchor: 'multiple-mc-management',
    copy: 'Easily manage separate companies all in one place, under one login.',
    image: '/images/features/screenshots/ma-02_Multiple-MC-Management.png',
  },
  {
    title: <>Reporting</>,
    anchor: 'reporting',
    copy: 'Analyze market rates, fleet expenses, profitability, aging reports and other actionable insights that help you improve efficiencies and profitability.',
    image: '/images/features/screenshots/ma-03_Reporting.png',
  },
];

const seamless_integrations = [
  {
    title: <>ELDs</>,
    anchor: 'elds',
    copy: 'Compatible with over 25 top ELDs for real-time tracking, HOS, and IFTA reporting have never been easier.',
    image: '/images/features/screenshots/in-01_ELDs.png',
  },
  {
    title: <>Quickbooks</>,
    anchor: 'quickbooks',
    copy: 'Sync invoice information and data from Carrier TMS to Quickbooks at the click of a button, saving the need to spend hours copying and pasting from one system to another.',
    image: '/images/features/screenshots/fa-03_Quickbooks.png',
  },
  {
    title: <>Load Board</>,
    anchor: 'load-board',
    copy: 'Carrier TMS provides a consolidated load board with integrations with your subscriptions to DAT, Loadsmart, and others. With the ‘book it now’ functionality, dispatchers can quickly search, book and dispatch a truck load within a few clicks.',
    image: '/images/features/screenshots/di-01_Load-Board.png',
  },
  {
    title: <>Fuel Cards</>,
    anchor: 'fuel-cards',
    copy: 'Import fuel expenses from driver’s fuel cards  for easy IFTA  and driver expense reports.',
    image: '/images/features/screenshots/in-02_Fuel-Cards.png',
  },
  {
    title: <>Shipper EDI</>,
    anchor: 'shipper-edi',
    copy: 'Connect EDI to your top customers to receive and accept tenders while avoiding data entry and expensive integration fees.',
    image: '/images/features/screenshots/in-03_Shipper-EDI.png',
  },
];

const featuredContent = [
  {
    header: {
      title: <>Finance &amp; Accounting</>,
      anchor: 'finance-accounting',
    },
    items: features_finance,
  },
  {
    header: {
      title: <>Back-Office Management</>,
      anchor: 'management',
    },
    items: back_office_management,
  },
  {
    header: {
      title: <>Seamless Integrations</>,
      anchor: 'integrations',
    },
    items: seamless_integrations,
  },
];

export default function FeaturesNavBanner({ variant }) {
  const [indexActive, setIndexActive] = useState(0);
  const router = useRouter();

  return (
    <>
      <FeaturesNavBannerWrapper id="scroll-top" variant={variant}>
        <StyledContainer variant={variant}>
          <Content variant={variant}>
            {variant === 'external' ? (
              <>
                <SmallText>Want more than the basics?</SmallText>
                <Title variant={variant}>
                  Unlock the full potential of your business with
                  <br />
                  <strong> Carrier TMS PRO</strong>
                </Title>
                <Text variant={variant}>
                  Get access to seamless tracking on every load, dozens of
                  plug-and-play integrations, and advanced reporting that gives
                  you actionable insights to help you make better decisions.
                </Text>
                <StyledButton as="a" href={`${router.asPath}features/`}>
                  Get to know all the PRO features
                </StyledButton>
              </>
            ) : (
              <>
                <Title variant={variant}>
                  <strong>One Simple Tool</strong>
                  <br /> To Manage <br /> Your Trucking <br />
                  Business
                </Title>
                <Text variant={variant}>
                  Carrier TMS is an intuitive turnkey truck management system
                  that drives profitability and connects carriers to an agnostic
                  marketplace of loads, carrier services, and TMS integrations
                </Text>
              </>
            )}
          </Content>
          <FeaturesNavBannerNav variant={variant}>
            {featuredContent.map((item, key) => {
              const keyFeatureWrapper = `FeaturesNavBannerNav-${key}`;
              return (
                <FeaturesNavBannerNavWrapper key={keyFeatureWrapper}>
                  <FeaturesNavBannerNavTitle
                    variant={variant}
                    className={indexActive === key ? 'opened' : 'closed'}
                    onClick={() => {
                      indexActive === key
                        ? setIndexActive(-1)
                        : setIndexActive(key);
                    }}
                  >
                    {item.header.title}
                  </FeaturesNavBannerNavTitle>
                  <FeaturesNavBannerNavGroup
                    className={indexActive === key ? 'opened' : 'closed'}
                    items={item.items.length}
                  >
                    {item.items.map((feat, keyf) => {
                      const keyFeature = `FeaturesNavBannerNavItem-${key}-${keyf}`;
                      return (
                        <FeaturesNavBannerNavItem
                          key={keyFeature}
                          href={`${
                            variant !== 'external'
                              ? ``
                              : `${router.asPath}features/`
                          }#${item.header.anchor}-${feat.anchor}`}
                          variant={variant}
                        >
                          {feat.title}
                        </FeaturesNavBannerNavItem>
                      );
                    })}
                  </FeaturesNavBannerNavGroup>
                </FeaturesNavBannerNavWrapper>
              );
            })}
          </FeaturesNavBannerNav>
        </StyledContainer>
      </FeaturesNavBannerWrapper>
      <MobileNav variant={variant}>
        <MobileNavWrapper variant={variant}>
          {featuredContent.map((item, key) => {
            const keyFeatMobile = `MobileNav-${key}`;
            return (
              <MobileNavTitle
                variant={variant}
                key={keyFeatMobile}
                href={`${
                  variant !== 'external' ? `` : `${router.asPath}features/`
                }#${item.header.anchor}`}
              >
                {item.header.title}
              </MobileNavTitle>
            );
          })}
        </MobileNavWrapper>
      </MobileNav>
    </>
  );
}

export { FeaturesNavBanner };
