//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '@/containers-m/Navigation';

import {
  Layout,
  Title,
  TitleH2,
  TitleH3,
  TitleH4,
  Paragraph,
  Link,
  BulltetList,
} from '@/components/Typography/Legal';

const PrivacyPolicy = () => {
  return (
    <>
      <Navigation />
      <>
        <Layout>
          <Title>Privacy Policy</Title>
          <Paragraph>
            <i>Last Updated: 10/29/21</i>
          </Paragraph>
          <Paragraph>
            This Privacy Policy describes how Loadsmart, Inc. (“
            <strong>we</strong>
            ,” “<strong>us</strong>
            ,” or “<strong>our”</strong>) collects, uses, and discloses your
            Personal Information when you use our website at{' '}
            <Link href="https://loadsmart.com/">https://loadsmart.com/</Link>{' '}
            (the “<strong>Site</strong>
            ”), our mobile application (the “<strong>App</strong>
            ”), and our Services. This includes when you subscribe to, enter
            your Personal Information on, download software from, log into your
            account from, post comments on, or otherwise access or use the
            various content, features, sales, or other services offered by us
            through the Site or App and related applications and software
            (collectively, the “<strong>Services</strong>
            ”). This Privacy Policy does not apply to information that we
            collect offline unless we specifically refer to this Privacy Policy
            in our offline communication with you. This Privacy Policy also does
            not apply to information that you provide to or that is collected by
            any third party.
          </Paragraph>
          <Paragraph>
            By using the Services, you consent to and agree that we may collect,
            process, use, retain, share, and transfer your Personal Information
            as described in this Privacy Policy. This Privacy Policy supplements
            and is incorporated into our Terms of Use. By accessing, registering
            with, uploading, downloading, procuring through, accepting bookings
            on, or otherwise using our Services, you agree to this Privacy
            Policy and our Terms of Use, including any updates thereto.
          </Paragraph>
          <TitleH2>
            <strong>1. Collecting Personal Information:</strong> Definition,
            Methods, Categories; Purposes; Third-Party Collections
          </TitleH2>
          <TitleH3>1.1 Personal Information:</TitleH3>
          <Paragraph>
            When you access our Services, we collect the information you provide
            to us, and certain information about your device and interaction
            with the Site. In this Privacy Policy, we refer to any information
            that can uniquely identify an individual as “
            <strong>Personal Information.</strong>” See the list below for more
            information about the means of collecting Personal Information, the
            categories of Personal Information we collect, the purpose of
            collection, and to whom we may disclose it and why. This Privacy
            Policy in no way restricts or limits our use of aggregate data.
          </Paragraph>
          <TitleH3>1.2 Information you provide to us:</TitleH3>
          <Paragraph>
            Personal Information you provide to us is collected directly from
            you for the business purpose of fulfilling your request to us,
            connecting warehouses and facilities to carriers, processing your
            payment information, communicating with you, and screening our Site
            for potential risk, copyright infringement, or fraud. You may
            provide us with your Personal Information when you visit the Site,
            register as a user, submit or post information, make or accept
            bookings, pay for Services you use, and when you request or use one
            of our other Services. If you elect to provide us with your Personal
            Information, you consent to its transfer and storage on our servers.
          </Paragraph>
          <Paragraph>
            Categories of Personal Information we collect directly from you
            includes:
          </Paragraph>
          <TitleH4>1.2.1 Identifiers:</TitleH4>
          <Paragraph>
            Such as your name, billing address, shipping address, email address,
            and phone number.
          </Paragraph>
          <TitleH4>1.2.2 Financial Information:</TitleH4>
          <Paragraph>
            Such as payment information and credit card numbers.
          </Paragraph>
          <TitleH4>1.2.3 Employment-related Information:</TitleH4>
          <Paragraph>
            If you are applying to be a certified carrier with us, this Personal
            Information may also include employment-related Information such as
            your work history, a background check, your driving records, your
            educational history, and the contact information of any references.{' '}
          </Paragraph>
          <TitleH4>1.2.4 Research Information:</TitleH4>
          <Paragraph>
            We may also ask you for additional Personal Information to help us
            understand you better including company revenues, number of
            employees, industry information, and other questions for research
            purposes. This information is not required to use the Services.{' '}
          </Paragraph>
          <TitleH4>1.2.5 Sensitive Information:</TitleH4>
          <Paragraph>
            We ask that you not send us or disclose any sensitive Personal
            Information (e.g., social security numbers, information related to
            racial or ethnic origin, political opinions, religion or other
            beliefs, health, biometrics or genetic characteristics, sex life or
            orientation, criminal background, or trade union membership).
          </Paragraph>
          <TitleH3>1.3 Device and Site Interaction Information:</TitleH3>
          <Paragraph>
            Device and Site interaction information is collected automatically
            and indirectly through the use of cookies, log files, web beacons,
            tags, or pixels when you access the Site or use our Services for the
            business purpose of loading and administering the Site accurately
            for you and to perform analytics on Site usage to optimize our Site
            and aid our understanding of who are users are.
          </Paragraph>
          <Paragraph>
            Examples of Personal Information we collect indirectly related to
            your device includes the version of web browser, IP address, time
            zone, cookie information, what sites or products you view, search
            terms, and how you interact with the Site.
          </Paragraph>
          <TitleH4>1.3.1 Tracking and Mobile Data:</TitleH4>
          <Paragraph>
            In order to perform its Services, when you use certain features of
            the Services, in particular our mobile applications, we may receive,
            collect, store, and process different types of information about
            your location and that of your assets (e.g. your trucks or your
            in-transit goods), including not only general information (e.g., IP
            address, zip code), but also more specific location-related
            information and truck diagnosis (e.g., GPS-based functionality on
            mobile devices used to access the platform or specific features of
            the platform, including tracking Users’ motor carrier vehicles and
            shipments transported therein, as well as extracting diagnostic
            information from trucks and other tracked assets).
          </Paragraph>
          <Paragraph>
            If you access the platform through a mobile device and you do not
            want your device to provide us with location-tracking information,
            you may disable the GPS or other location-tracking functions on your
            mobile device, provided your device allows you to do this. See your
            device manufacturer’s instructions for further details.
          </Paragraph>
          <Paragraph>
            If one of our tracking devices has been provided to you and
            installed in your vehicles or provided to your drivers, you
            expressly consent to the collection, use, processing, disclosure,
            and retention of all information described herein. If you do not
            want to provide us with this information, you may uninstall and
            return such tracking devices to us.
          </Paragraph>
          <TitleH4>1.3.2 Storing and Tracking:</TitleH4>
          <Paragraph>
            We may track your precise location and that of your Assets. You
            understand and agree that we and/or our agents or service providers
            may monitor your activities and locations, including your use of the
            App and its Services. Specifically, by using the App or any of the
            Services, you consent to us collecting and using Personal
            Information about you and your Assets to (i) administer the Services
            and (ii) electronically locate you or, if applicable, your Assets
            equipped with a GPS in conjunction with providing you and your
            customers the Services.
          </Paragraph>
          <TitleH4>1.3.3 Analytics:</TitleH4>
          <Paragraph>
            We use services like Google Analytics to help us understand how our
            customers use the Site. You can read more about how Google uses your
            Personal Information here:{' '}
            <Link
              target="_blank"
              href="https://policies.google.com/privacy?hl=en"
            >
              https://policies.google.com/privacy?hl=en
            </Link>
            . You can also opt-out of Google Analytics here:{' '}
            <Link
              target="_blank"
              href="https://tools.google.com/dlpage/gaoptout"
            >
              https://tools.google.com/dlpage/gaoptout
            </Link>
            .{' '}
          </Paragraph>
          <TitleH3>1.4 Customer and employee consent:</TitleH3>
          <Paragraph>
            YOU ACKNOWLEDGE AND AGREE YOU ARE RESPONSIBLE (1) FOR ADVISING YOUR
            MOTOR VEHICLE DRIVERS OR, IF APPLICABLE, YOUR CUSTOMERS IF YOUR
            CUSTOMERS’ ASSETS ARE EQUIPPED WITH GPS UNITS ABOUT HOW WE MAY
            COLLECT, USE, AND DISCLOSE INFORMATION ABOUT THEM AND THEIR ASSETS
            AND (2) FOR OBTAINING WHATEVER CONSENTS AND WAIVERS FROM THIRD
            PARTIES MAY BE NECESSARY IN ORDER FOR US TO COLLECT, USE, AND
            DISCLOSE SUCH INFORMATION. YOUR OBLIGATION TO OBTAIN ANY REQUIRED
            CONSENTS EXISTS REGARDLESS OF WHETHER WE SEEK PROOF OF YOU
            FULFILLING YOUR OBLIGATIONS.
          </Paragraph>
          <TitleH3>1.5 Third Party Collection:</TitleH3>
          <Paragraph>
            Certain third parties may use automatic information collection
            technologies, including cross-site tracking technologies, to collect
            Personal Information about you or your device. These third parties
            may include, but are not limited to, your internet service provider,
            your web browser, your mobile service provider, your mobile device
            manufacturer, online advertisers, and data analytics companies. We
            do not control these third parties or how they collect, use, or
            disclose your Personal Information. As stated above, this Privacy
            Policy is not applicable to third party collection, use, or
            disclosure of your Personal Information. If you have any questions
            about the privacy practices of any third party, you should contact
            the responsible third party directly.
          </Paragraph>
          <TitleH4>1.5.1 Social Media:</TitleH4>
          <Paragraph>
            The Site may contain links to our accounts on Facebook, Twitter,
            LinkedIn or other similar social network. Clicking on any such links
            means that the respective social network will receive information on
            from which website you accessed the platform and may collect other
            Personal Information. It is also possible, if you are currently
            logged in as a user to the social network in question, that the
            social network will link this information to your account with the
            social network.
          </Paragraph>
          <TitleH2>
            <strong>2. Sharing Personal Information:</strong> Categories,
            Purposes
          </TitleH2>
          <TitleH3>
            2.1 Subsidiaries, Affiliates, and Business Partners:
          </TitleH3>
          <Paragraph>
            We may share your Personal Information within our corporate group,
            including with our subsidiaries, parent organization, and business
            affiliates in accordance with this Privacy Policy and applicable
            law. We do so for the purposes of fulfilling your requests to us,
            performing our contract with you, providing better service to our
            customers, and managing warranty, repair, or customer feedback.
          </Paragraph>
          <TitleH3>2.2 Service Providers and Contractors:</TitleH3>
          <Paragraph>
            We may share your Personal Information with service providers and
            contractors to help us provide our Services and fulfill obligations
            to you. These service providers and contractors may include database
            and cloud service providers, billing systems, customer management
            services, payment processors, shipping companies, installation and
            cybersecurity services, and employee management and benefits
            programs.
          </Paragraph>
          <Paragraph>
            We may disclose the Personal Information you share with us to our
            vendors, contractors, or service providers for a legitimate business
            purpose such as to process your payments, conduct a background
            check, or ship your products to you. We may disclose the Personal
            Information we collect indirectly from you with us to our vendors,
            contractors, or service providers for a legitimate business purpose
            such as for search engine optimization, Site processing, and Site
            performance and analytics.
          </Paragraph>
          <TitleH3>2.3 Advertising:</TitleH3>
          <Paragraph>
            We may provide advertisements targeted to you based on the Personal
            Information we retain about you, such as information relevant to
            your current location. In some circumstances we may also sell your
            Personal Information to advertisers and other partners.
          </Paragraph>
          <TitleH3>2.4 Asset Transfer:</TitleH3>
          <Paragraph>
            We may share your Personal Information with a buyer or successor in
            the event of a merger, divestiture, restructuring, reorganization,
            dissolution, or other sale or transfer of some or all of our assets,
            whether as a going concern or as part of bankruptcy, liquidation, or
            similar proceeding, in which Personal Information held by us is
            among the assets being transferred.
          </Paragraph>
          <TitleH3>2.5 Legal Requests:</TitleH3>
          <Paragraph>
            We may share your Personal Information when required to do so by
            law, court order, with a buyer or successor in the event of a
            merger, divestiture, restructuring, reorganization, or other legal
            process such as a subpoena. We generally do not disclose Personal
            Information unless we have a good faith belief that an information
            request by law enforcement or private litigants meets applicable
            legal standards. We may share your Personal Information when we
            believe it is necessary to comply with applicable laws, to protect
            our interests or property, to prevent fraud or other illegal
            activity perpetrated through the services or using our name, or to
            protect the safety of any person. This may include sharing Personal
            Information with other companies, lawyers, agents, or government
            agencies. Nothing in this Privacy Policy is intended to limit any
            legal defenses or objections that you may have to a third party’s,
            including a government’s, request to disclose your information.
          </Paragraph>
          <TitleH3>2.6 Sharing with Consent:</TitleH3>
          <Paragraph>
            We may share your Personal Information at your direction or any time
            we have your consent to do so, including implicit and tacit consent.
            This includes sharing your Personal Information as we have disclosed
            in this Privacy Policy.
          </Paragraph>
          <TitleH3>2.7 Depersonalized Information:</TitleH3>
          <Paragraph>
            We may share your Personal Information after it is aggregated or
            otherwise depersonalized such that it is no longer considered
            Personal Information.{' '}
          </Paragraph>
          <TitleH2>
            <strong>3. Your Choices and Rights:</strong> Sharing Choices, U.S.
            Law, European Economic Area, International Transfers
          </TitleH2>
          <TitleH3>3.1 Your Choices:</TitleH3>
          <Paragraph>
            We respect your privacy and seek to provide you with options to
            manage the Personal Information collected about you while you are
            using our Services. We abide by the privacy laws applicable to you
            based upon your jurisdiction.
          </Paragraph>
          <TitleH4>3.1.1 Emails:</TitleH4>
          <Paragraph>
            You may opt-out of receiving marketing messages from us and any of
            our Affiliates by unsubscribing through the unsubscribe or opt-out
            link in an email, or by emailing us at the address provided below in
            the contact section of this Privacy Policy. We will try to comply
            with your request(s) as soon as reasonably practicable. Please note
            that even if you opt-out of receiving marketing-related emails from
            us, we will still send you important accounts, purchase
            confirmation, and administrative messages.
          </Paragraph>
          <TitleH4>3.1.2 Managing Cookies:</TitleH4>
          <Paragraph>
            You can control and manage cookies in various ways. Please keep in
            mind that removing or blocking cookies can negatively impact the
            functionality of the Site and your experience accessing our
            Services. Most browsers automatically accept cookies, but you can
            choose whether or not to accept cookies through your browser
            controls, often found in your browser’s “Tools” or “Preferences”
            menu. For more information on how to modify your browser settings or
            how to block, manage or filter cookies can be found in your
            browser’s help file or through such sites as{' '}
            <Link target="_blank" href="https://www.allaboutcookies.org">
              www.allaboutcookies.org
            </Link>
            .
          </Paragraph>
          <TitleH4>3.1.3 Do Not Track:</TitleH4>
          <Paragraph>
            Please note that because there is no consistent industry
            understanding of how to respond to “Do Not Track” signals, we do not
            alter our data collection and usage practices when we detect such a
            signal from your browser.
          </Paragraph>
          <TitleH3>3.2 Notice to California Residents:</TitleH3>
          <Paragraph>
            Each capitalized term used, but not defined, in this section shall
            have the meaning given to such term in the California Consumer
            Privacy Act of 2018 (“CCPA”). If you are a resident of California,
            you may have the right to access the Personal Information we hold
            about you, to ask that your Personal Information be corrected,
            updated, ported, or erased, and to opt out of the sale of your
            Personal Information. If you would like to exercise these rights,
            please contact us through the means indicated at the end of this
            Privacy Policy. If you would like to designate an authorized agent
            to submit these requests on your behalf, please contact us through
            the means indicated at the end of this Privacy Policy.
          </Paragraph>
          <Paragraph>
            Please note the CCPA contains certain exceptions to applicability
            with respect to employees, job applicants and business contacts.
            These rights do not apply to California job applicants, employees,
            or business contacts.
          </Paragraph>
          <TitleH4>3.2.1 Right of Access to Specific Information:</TitleH4>
          <Paragraph>
            You have the right to request that we disclose certain information
            to you about our collection and use of your Personal Information
            over the past 12 months. Once we receive and confirm your verifiable
            Consumer request, we will disclose to you:
          </Paragraph>

          <BulltetList>
            <li>
              <Paragraph>
                ● The categories of Personal Information we collected about you;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                ● The categories of sources for the Personal Information we
                collected about you;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                ● Our business or commercial purpose for collecting, sharing, or
                selling that Personal Information;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                ● The categories of third parties with whom we share that
                Personal Information;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                ● The specific pieces of Personal Information we collected about
                you (also called a data portability request) and provide a copy
                to you in an electronic or paper format; and
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                ● The categories of Personal Information, if any, we disclosed
                for a business purpose to a third party.
              </Paragraph>
            </li>
          </BulltetList>

          <TitleH4>3.2.2 Deletion Request Right:</TitleH4>
          <Paragraph>
            You have the right to request that we delete any of your Personal
            Information that we collected from you and retained, subject to
            certain exceptions. Once we receive and confirm your verifiable
            Consumer request, we will delete (and direct our Contractors and
            Service Providers to delete) your Personal Information from our (and
            their) records, unless an exception applies (as described below).
          </Paragraph>
          <Paragraph>
            As permitted by CCPA we may delete your Personal Information by (a)
            permanently and completely erasing the Personal Information on our
            existing systems with the exception of archived or back-up systems;
            (b) de-identifying the Personal Information; or, (c) aggregating the
            Personal Information.
          </Paragraph>
          <Paragraph>
            We may deny your deletion request if retaining the information is
            necessary for us or one of our Contractors or Service Providers to:
          </Paragraph>

          <BulltetList>
            <li>
              <Paragraph>
                ● Complete the transaction for which we collected the Personal
                Information, provide a good or service that you requested, take
                actions reasonably anticipated within the context of our ongoing
                business relationship with you, or otherwise perform our
                contract with you;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                ● Detect security incidents, protect against malicious,
                deceptive, fraudulent, or illegal activity, or prosecute those
                responsible for such activities;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                ● Debug products to identify and repair errors that impair
                existing intended functionality;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                ● Exercise free speech, ensure the right of another consumer to
                exercise their free speech rights, or exercise another right
                provided for by law;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                ● Comply with the California Electronic Communications Privacy
                Act (Cal. Penal Code § 1546 et. seq.);
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                ● Enable solely internal uses that are reasonably aligned with
                consumer expectations based on your relationship with us;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                ● Comply with a legal, regulatory or law enforcement obligation;
                or
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                ● Make other internal and lawful uses of that information that
                are compatible with the context in which you provided it.
              </Paragraph>
            </li>
          </BulltetList>

          <TitleH4>
            3.2.3 Exercising Access, Data Portability, Correction, and Deletion
            Rights:
          </TitleH4>
          <Paragraph>
            To exercise the access, data portability, and deletion rights
            described above, please submit a verifiable Consumer request to us
            by calling using the contact information at the end of this Privacy
            Policy. Only you, or a person registered with the California
            Secretary of State that you authorize to act on your behalf, may
            make a verifiable Consumer request related to your Personal
            Information. You may only make a verifiable Consumer request for
            access or data portability twice within a 12-month period. To be
            verifiable, the Consumer request must:
          </Paragraph>

          <BulltetList>
            <li>
              <Paragraph>
                ● Provide sufficient information that allows us to reasonably
                verify you are the person about whom we collected Personal
                Information or an authorized representative of that person and
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                ● Describe your request with sufficient detail that allows us to
                properly understand, evaluate, and respond to it.
              </Paragraph>
            </li>
          </BulltetList>

          <Paragraph>
            We cannot respond to your request or provide you with Personal
            Information if we cannot verify your identity or authority to make
            the request and confirm that the Personal Information relates to
            you. Making a verifiable Consumer request does not require you to
            create an account with us. We will only use Personal Information
            provided in a verifiable Consumer request to verify the requestor’s
            identity or authority to make the request.
          </Paragraph>
          <Paragraph>
            Upon receiving a data access, correction, data port or deletion
            request from you, we will verify your identity based on the
            information we have on file for you. Upon verification of your
            identity, we will proceed to process your request (subject to the
            exceptions stated above).
          </Paragraph>
          <Paragraph>
            We endeavor to confirm receipt of your request within ten (10) days
            of receiving it. We will respond to a verifiable Consumer request
            within forty-five (45) days of its receipt. If we require more time
            (up to an additional forty-five (45) days), we will inform you of
            the reason and extension period in writing.
          </Paragraph>
          <Paragraph>
            We will deliver our written response via email to the email address
            associated with you or, if we are unable to determine your email
            address, via mail.
          </Paragraph>
          <Paragraph>
            Any disclosures we provide will only cover the 12-month period
            preceding the verifiable Consumer request receipt. The response we
            provide will also explain the reasons we cannot comply with a
            request, if applicable. For data portability requests, we will
            select a format to provide your Personal Information that is readily
            usable and should allow you to transmit the information from one
            entity to another entity without hindrance.
          </Paragraph>
          <Paragraph>
            We do not charge a fee to process or respond to your verifiable
            Consumer request unless it is excessive, repetitive, or manifestly
            unfounded. If we determine that the request warrants a fee, we will
            tell you why we made that decision and provide you with a cost
            estimate before completing your request.
          </Paragraph>
          <TitleH4>3.2.4 Non-Discrimination:</TitleH4>
          <Paragraph>
            We will not discriminate against you simply for exercising your
            rights under the CCPA.
          </Paragraph>
          <TitleH3>3.3 Notice to Nevada Residents:</TitleH3>
          <Paragraph>
            If you are a Nevada resident who wishes to exercise your sale
            opt-out rights under Nevada Revised Statutes Chapter 603A you may
            submit a request to the contact information listed at the end of
            this Privacy Policy.
          </Paragraph>
          <TitleH3>3.4 Notice to Residents of Non-U.S. Countries:</TitleH3>
          <Paragraph>
            Our headquarters is in the United States. The Personal Information
            we or our service providers and contractors collect may be stored
            and processed in servers within or outside of the United States and
            certain Personal Information may be accessible by persons or
            companies outside of the United States who provide services for us.
            As such, we and our service providers and contractors may transfer
            your Personal Information to, or access it in, jurisdictions that
            may not provide equivalent levels of data protection as your home
            jurisdiction. We will take reasonable steps to ensure that your
            Personal Information receives an adequate level of protection in the
            jurisdictions in which we process it.{' '}
          </Paragraph>
          <Paragraph>
            <strong>
              <i>
                If you are a resident of a country other than the United States,
                you acknowledge and consent to our collecting, transmitting,
                transferring, processing, storing, and otherwise using your
                Personal Information outside of the country in which you reside.
              </i>
            </strong>
            Your acceptance of this Privacy Policy or use of our Site or
            Services constitutes consent, either express, implied, or tacit, to
            collect, use, and share your Personal Information as indicated
            herein.
          </Paragraph>
          <TitleH3>
            3.5 Notice to Residents of the European Union and United Kingdom:
          </TitleH3>
          <Paragraph>
            If you are a resident of the European Union or the United Kingdom
            (also referred to as a “Data Subject”), the General Data Protection
            Regulation (“GDPR”) provides you with additional rights regarding
            our use of your personal information. This section is provided
            pursuant to the GDPR. Each capitalized term used, but not defined,
            in this section shall have the meaning given to such term in the
            GDPR. This section describes how we collect, share, disclose, and
            process the Personal Data of residents of the European Union and
            United Kingdom. “Personal Data” means any information relating to an
            identified or identifiable natural person (referred to ‘data
            subject’); an identifiable natural person is one who can be
            identified, directly or indirectly, in particular by reference to an
            identifier such as a name, an identification number, location data,
            an online identifier or to one or more factors specific to the
            physical, physiological, genetic, mental, economic, cultural or
            social identity of that natural person and includes all “Personal
            Information” referred to in this Policy.
          </Paragraph>
          <TitleH4>
            3.5.1 Rights of European Union and United Kingdom Residents:
          </TitleH4>
          <Paragraph>
            If you are located in the European Union or the United Kingdom, you
            have certain rights regarding the Personal Data that we maintain
            about you, which in certain circumstances you will be able to
            exercise. The rights are as follows:
          </Paragraph>

          <BulltetList>
            <li>
              <Paragraph>
                ● <strong>Access:</strong> You may request a copy of the
                Personal Data we maintain about you.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                ● <strong>Portability:</strong> If we maintain your Personal
                Data based on your consent or so that we can enter into or
                perform under a contract with you, you have the right to obtain
                your Personal Data from us that you consented to give us, that
                is necessary to enter into or perform the contract, or that is
                necessary to provide member benefits to you. We will give you
                your Personal Data in a structured, commonly used and
                machine-readable format.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                ● <strong>Correction:</strong> If you believe your Personal Data
                is incorrect, you may request that we correct, amend or delete
                your Personal Data that is inaccurate or incomplete.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                ● <strong>Deletion or Restriction of Processing:</strong> You
                may request that we erase or restrict the processing of your
                Personal Data.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                ● <strong>Object to Processing:</strong> You may object to the
                processing of your Personal Data in certain circumstances when
                we process your Personal Data for the purposes of our legitimate
                interests.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                ● <strong>Right to Complain:</strong> You have the right to file
                a complaint with a supervisory authority, in particular in the
                European member state of your habitual residence, place of work
                or place of the alleged infringement if you consider that the
                processing of your Personal Data infringes upon your rights.
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                ● <strong>Withdraw Consent:</strong> If we are processing your
                Personal Data based on your consent to do so, you may withdraw
                that consent at any time.
              </Paragraph>
            </li>
          </BulltetList>

          <Paragraph>
            Some of these rights can be exercised online via your account. For
            example, you can review and modify certain information relating to
            your use of our Services and unsubscribe to email messages. If you
            are unable to manage your request via your online account, then
            please contact us using the contact information at the end of this
            Policy.
          </Paragraph>
          <TitleH4>3.5.2 Collection of Personal Data:</TitleH4>
          <Paragraph>
            We collect Personal Data that is reasonably necessary for us to
            provide our Services. Subject to applicable exceptions, we will
            obtain your consent before collecting, using and disclosing your
            Personal Data for the specified purposes. In addition to
            circumstances in which you consent to the use of your Personal Data,
            the Personal Data we collect may be used or processed where we have
            a legitimate interest in or other legal basis for processing such
            data.
          </Paragraph>
          <Paragraph>
            The categories of your Personal Data we collect and the purposes for
            which we collect such Personal Data are indicated above in the
            section entitled “Collecting Personal Information.” If we use
            Personal Data in ways other than described in this Policy, we will
            provide you with specific notice at the time of collection.
          </Paragraph>
          <TitleH4>3.5.3 Sharing Personal Data:</TitleH4>
          <Paragraph>
            The ways in which we share your Personal Data and the purposes for
            which we share it are indicated above in the section entitled
            “Sharing Personal Information.” If we use Personal Data in ways
            other than described in this Policy, we will provide you with
            specific notice at the time of collection.
          </Paragraph>
          <TitleH4>3.5.4 Processing Personal Data:</TitleH4>
          <Paragraph>
            We only process Personal Data when we have a legal basis to do so
            including:
          </Paragraph>

          <BulltetList>
            <li>
              <Paragraph>
                ● When you have consented to the processing of your Personal
                Data, including processing your Personal Data consistent with
                this Policy, including the advertising purposes disclosed in
                this Policy;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                ● When processing is necessary to perform our obligations to you
                or fulfill a request you have made to us;
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                ● When processing is necessary to comply with a legal obligation
                that applies to us; or
              </Paragraph>
            </li>
            <li>
              <Paragraph>
                ● When processing is necessary for purposes that are in our
                legitimate interests and you have given any required explicit or
                implicit consent to such processing, including protecting the
                security of our Services, improving the functioning of our
                Services, or providing you with information about products in
                which you have expressed interest.
              </Paragraph>
            </li>
          </BulltetList>

          <Paragraph>
            If we need to process your Personal Data to fulfill our obligations
            to you, failure to provide that Personal Data will mean that we
            cannot perform our obligations. If we collect your Personal Data for
            one purpose and need to process it for a second purpose, we will
            provide you with additional information regarding this secondary
            purpose to ensure fair and transparent processing before we engage
            in further processing. Collecting and processing your Personal Data
            to create both general and targeted advertising is a primary purpose
            for collecting your Personal Data.
          </Paragraph>
          <TitleH2>
            <strong>4. General Disclosures</strong>
          </TitleH2>
          <TitleH3>4.1 Retention:</TitleH3>
          <Paragraph>
            When you access our Services, we will retain your Personal
            Information as long as it is necessary for the processing purpose in
            question or in accordance with our data retention policy. We
            determine the appropriate retention period based on the nature and
            sensitivity of the Personal Information being processed, the risk of
            harm due to unauthorized access versus the benefit of retention, and
            whether we can achieve the purposes of processing through other
            means. We may retain your Personal Information for longer periods if
            required by law, if you give us your permission, or in case of a
            legal dispute in which your Personal Information may be used as
            evidence.
          </Paragraph>
          <TitleH3>4.2 Automatic decision-making:</TitleH3>
          <Paragraph>
            We do not profile or use automated decision making.
          </Paragraph>
          <TitleH3>4.3 Telephone Monitoring:</TitleH3>
          <Paragraph>
            {' '}
            As part of our customer service assurance practice, telephone
            conversations over phones may be monitored or recorded as a part of
            normal business operations. Monitored or recorded calls will be used
            for quality assurance and training purposes.{' '}
          </Paragraph>
          <TitleH3>4.4 Cybersecurity:</TitleH3>
          <Paragraph>
            We use robust security measures to protect your information from
            unauthorized access, maintain data accuracy, and help ensure the
            appropriate use of information. When the Services are accessed using
            the Internet, we use Transport Layer Security (TLS) technology to
            protect information using both server authentication and data
            encryption. We also implement other advanced technology measures to
            prevent interference or access from outside intruders. We offer
            enhanced security features within the Services that permit users to
            configure security settings to the level they deem necessary, such
            as forced password changes and IP-restrictions.
          </Paragraph>
          <TitleH4>4.4.1 </TitleH4>
          <Paragraph>
            The safety and security of your information also depends on you.
            Where we have given you (or where you have chosen) a password and/or
            username for access to certain parts of our Service, you are
            responsible for keeping such information confidential. We ask you
            not to share your password or username with anyone. We ask you to
            select unique and secure passwords when setting up profiles in our
            Service.
          </Paragraph>
          <TitleH4>4.4.2</TitleH4>
          <Paragraph>
            Unfortunately, the transmission of information via the Internet is
            not completely secure. Although we do our best to protect your
            information, we cannot guarantee the security of your information
            transmitted to our Service. Any transmission of Personal Data is at
            your own risk. We are not responsible for circumvention of any
            privacy settings or security measures contained on the Service.
          </Paragraph>
          <TitleH3>4.5 Changes to this Privacy Policy:</TitleH3>
          <Paragraph>
            We may update this Privacy Policy from time to time.
          </Paragraph>
          <TitleH3>4.6 Contact Information:</TitleH3>
          <Paragraph>
            For more information about our privacy practices, if you have
            questions, or if you would like to make a complaint, please contact
            us at: <strong>legal@loadsmart.com</strong>.
          </Paragraph>
        </Layout>
      </>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
export { PrivacyPolicy };
