/* eslint-disable @next/next/no-img-element */
// components
import { Container } from '@/components/Grid';

//logos
import ShipperGuideLogo from '@/public/images/logos/shipperguide-logo.svg';

// styles
import {
  SidePanelsWrapper,
  SectionTitle,
  SectionIntro,
  SectionPanel,
  BrandWrapper,
  LogoWrapper,
  Title,
  Subtitle,
  Content,
  Link,
  Picture,
  Wrapper,
} from './SidePanels.styles';

const panels = [
  {
    title: 'Reliable Contracts',
    logo: null,
    subtitle: '100% PTA in any market condition.',
    content: (
      <>
        <p>
          Loadsmart’s Reliable Contracts is a dynamic solution that offers 100%
          PTA in any market condition. Unlike traditional cost-plus programs,
          Reliable Contracts ensure shippers always get the lowest available
          cost. Our transparent margins only go up when your costs go down.
        </p>
      </>
    ),
    link: {
      external: true,
      url: 'https://lp.loadsmart.com/reliable-contracts',
      label: 'Learn more about Reliable Contracts',
    },
    image: {
      path: {
        small: '/images/shipper/panels/item-01-small.jpg',
        medium: '/images/shipper/panels/item-01-large.jpg',
        large: '/images/shipper/panels/item-01-large.jpg',
        xxlarge: '/images/shipper/panels/item-01-xxlarge.jpg',
      },
      alt: 'A man in a couch working in his laptop',
    },
  },
  {
    title: null,
    logo: (
      <BrandWrapper>
        <LogoWrapper>
          <ShipperGuideLogo />
        </LogoWrapper>{' '}
        &nbsp;TMS
      </BrandWrapper>
    ),
    subtitle: 'Plan, Procure and Execute freight - all in one place.',
    content: (
      <>
        <p>
          A lack of automation and tools that are not integrated make navigating
          through the logistics process a pain. With ShipperGuide TMS you have
          one tool to centralize and increase end to end visibility of your
          logistics process. Plan, procure, quote, tender and ship for both your
          contracts and spot freight. <br />
          Freight planning, procurement and execution that works.
        </p>
      </>
    ),
    link: {
      external: false,
      url: '/shipper/shipperguide/',
      label: 'Learn more about ShipperGuide TMS',
    },
    image: {
      path: {
        small: '/images/shipper/panels/item-03-small.png',
        medium: '/images/shipper/panels/item-03-large.png',
        large: '/images/shipper/panels/item-03-large.png',
        xxlarge: '/images/shipper/panels/item-03-xxlarge.png',
      },
      alt: 'RFP Guide dashboard screeenshots',
    },
  },
  {
    title: 'Managed transportation',
    logo: null,
    subtitle:
      'Outsource your transportation needs to a team of logistics experts.',
    content: (
      <>
        <p>
          Outsource network optimization, planning, procuring, management, and
          execution of your transportation logistics, all while optimizing cost.
          Managed transportation services leverage Loadsmart’s sophisticated,
          proprietary applications, experienced, customer specific operations
          teams, and extensive carrier network.
        </p>
      </>
    ),
    link: {
      external: false,
      url: '/shipper/managed-transportation',
      label: 'Enable managed transportation services',
    },
    image: {
      path: {
        small: '/images/shipper/panels/item-04-small.jpg',
        medium: '/images/shipper/panels/item-04-large.jpg',
        large: '/images/shipper/panels/item-04-large.jpg',
        xxlarge: '/images/shipper/panels/item-04-xxlarge.jpg',
      },
      alt: 'Two people working in a stand up table with a laptop',
    },
  },
];

export function SidePanels() {
  return (
    <SidePanelsWrapper>
      <Container>
        <SectionTitle>
          Bringing freight technology and <br /> logistics services together
        </SectionTitle>
        <SectionIntro>
          Digital freight brokerage services, tools and pricing structures for
          FTL, LTL, PTL and drayage. Increase control, efficiency and visibility
          in your shipping operations.
        </SectionIntro>
        {panels.map((item, key) => {
          const panelKey = `sidepanel-${key}`;
          return (
            <SectionPanel key={panelKey} direction={key % 2 ? `reverse` : ``}>
              <Picture direction={key % 2 ? `reverse` : ``}>
                <source
                  srcSet={`${item.image.path.xxlarge}`}
                  media="(min-width: 1920px)"
                />
                <source
                  srcSet={`${item.image.path.large}`}
                  media="(min-width: 1024px)"
                />
                <source
                  srcSet={`${item.image.path.medium}`}
                  media="(min-width: 768px)"
                />
                <img
                  src={`${item.image.path.small}`}
                  alt={item.image.alt}
                  width="677"
                  height="520"
                />
              </Picture>
              <Wrapper direction={key % 2 ? `reverse` : ``}>
                {item.logo && <LogoWrapper>{item.logo}</LogoWrapper>}
                {item.title && <Title>{item.title}</Title>}
                {item.subtitle && <Subtitle>{item.subtitle}</Subtitle>}
                <Content>{item.content}</Content>
                <Link
                  href={item.link.url}
                  target={item.link.external ? '_blank' : null}
                  rel={item.link.external ? 'noreferrer noopener' : null}
                >
                  {item.link.label}
                </Link>
              </Wrapper>
            </SectionPanel>
          );
        })}
      </Container>
    </SidePanelsWrapper>
  );
}
