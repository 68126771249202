import React from 'react';

// components
import { Container } from '@/components/Grid/Container';

import { FeatureIcon, responsiveIconStyle } from '@/components/Feature';

// icons
import {
  IconTenderAcceptance,
  IconStockOptions,
  IconTailoredLoads,
} from '@loadsmart/icons';

// styles
import {
  SolutionsWrapper,
  Title,
  StyledFeatureGroup,
  StyledFeature,
  StyledFeatureTitle,
  Link,
  StyledFeatureDescription,
} from './SolutionsSection.styles';

export function SolutionsSection() {
  return (
    <SolutionsWrapper>
      <Container>
        <Title>FREE solutions to keep your trucks full & moving</Title>
        <StyledFeatureGroup>
          <StyledFeature>
            <FeatureIcon>
              <IconTailoredLoads role="img" css={responsiveIconStyle} />
            </FeatureIcon>
            <StyledFeatureTitle as="h3">
              Loadsmart Fuel Program
            </StyledFeatureTitle>
            <StyledFeatureDescription>
              Seamlessly make secure payments and save on fuel, everywhere you
              go.
            </StyledFeatureDescription>
            <Link
              href="https://partners.onrampcard.com/loadsmart"
              target="_blank"
            >
              Start saving
            </Link>
          </StyledFeature>
          <StyledFeature>
            <FeatureIcon>
              <IconTenderAcceptance role="img" css={responsiveIconStyle} />
            </FeatureIcon>
            <StyledFeatureTitle as="h3">
              FREE
              <br /> Carrier TMS
            </StyledFeatureTitle>
            <StyledFeatureDescription>
              Simplify and streamline everyday tasks for your fleet so you can
              focus on profits.
            </StyledFeatureDescription>
            <Link
              href="https://tms.kamion.io/login?state=checkAccount&__hstc=32328097.74da73e6aea128bbf7b33d90935d2139.1631884434263.1677597267980.1677600269152.372&__hssc=32328097.1.1677600269152&__hsfp=1404585697"
              target="_blank"
            >
              Start for Free
            </Link>
          </StyledFeature>
          <StyledFeature>
            <FeatureIcon>
              <IconStockOptions role="img" css={responsiveIconStyle} />
            </FeatureIcon>
            <StyledFeatureTitle as="h3">
              2.5% Truck Factoring
            </StyledFeatureTitle>
            <StyledFeatureDescription>
              Fast, simple, no hidden fees. Stop waiting 30+ days to get paid by
              your broker or shipper.
            </StyledFeatureDescription>
            <Link
              href="https://lp.loadsmart.com/factoring-sign-up"
              target="_blank"
            >
              Get Started
            </Link>
          </StyledFeature>
        </StyledFeatureGroup>
      </Container>
    </SolutionsWrapper>
  );
}
