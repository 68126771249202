import styled, { keyframes } from 'styled-components';
import { screen } from '@/helpers/screen';

const slideIn = keyframes`
    0% {
        opacity: 0;
    }
    60% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
`;

const slideLeft = keyframes`
    0% {
        -webkit-transform: translateX(-30%);
        transform: translateX(-30%);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
`;

export const PropertiesCarouselWrapper = styled.section`
  width: 100%;
  padding: 30px 0 20px;
  overflow: hidden;
  text-align: center;
  background: #f6f7f8;

  ${screen.md} {
    padding: 80px 0 30px;
  }
  ${screen.lg} {
    padding: 70px 0 45px;
  }
  ${screen.xxlg} {
    padding: 94px 0 50px;
  }
`;

export const Title = styled.h1`
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  ${screen.md} {
    margin-bottom: 40px;
    font-size: 27px;
    line-height: 40px;
  }
  ${screen.lg} {
    margin-bottom: 50px;
    font-size: 38px;
    line-height: 50px;
  }
  ${screen.xxlg} {
    margin-bottom: 55px;
    font-size: 48px;
    line-height: 72px;
  }
  strong {
    color: ${(props) => props.color || `inherit`};
  }
  &.inactive {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }
  &.active {
    position: relative;
    -webkit-animation: ${slideIn} 0.5s;
    animation: ${slideIn} 0.5s;
  }
`;

export const TitleSlider = styled.header`
  position: relative;
`;

export const LogosNavigation = styled.nav`
  display: grid;
  grid-template-columns: repeat(3, 30%);
  gap: 20px;
  align-items: center;
  justify-content: space-between;
`;

export const LogoItem = styled.div`
  display: inline-flex;
  margin: auto;
  svg {
    display: inline-flex;
    max-width: 100%;
    margin: auto;
    cursor: pointer;
  }
  &.inactive {
    svg {
      &:not(:hover) {
        path {
          fill: ${({ theme }) => theme.color.neutral.light};
        }
      }
    }
  }
  &.carrierTMS {
    width: 94px;
  }
  &.rfpguide {
    width: 94px;
  }
  &.opendock {
    width: 94px;
  }
  ${screen.md} {
    &.carrierTMS {
      width: 193px;
    }
    &.rfpguide {
      width: 153px;
    }
    &.opendock {
      width: 153px;
    }
  }
  ${screen.lg} {
    &.carrierTMS {
      width: 251px;
    }
    &.rfpguide {
      width: 251px;
    }
    &.opendock {
      width: 251px;
    }
  }
  ${screen.xxlg} {
    &.carrierTMS {
      width: 397px;
    }
    &.rfpguide {
      width: 397px;
    }
    &.opendock {
      width: 397px;
    }
  }
`;

export const BulletNavigation = styled.nav`
  display: flex;
  justify-content: center;
`;

export const BulletItem = styled.button`
  display: flex;
  width: 11px;
  height: 11px;
  margin: 5px;
  padding: 0;
  background: ${({ theme }) => theme.color.neutral.light};
  border: none;
  border-radius: 100%;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  span {
    display: none;
    overflow: hidden;
    text-indent: -299em;
  }
  &:hover,
  &.active {
    background: ${({ theme }) => theme.color.neutral.default};
    transition: all 0.15s ease-in-out;
  }
  ${screen.lg} {
    width: 15px;
    height: 15px;
    margin: 10px;
  }
  ${screen.xxlg} {
    width: 20px;
    height: 20px;
    margin: 15px;
  }
`;

export const SlidesList = styled.div`
  padding: 0 0 20px;
  ${screen.md} {
    padding: 24px 0;
  }
  ${screen.lg} {
    padding: 38px 0;
  }
  ${screen.xxlg} {
    padding: 42px 0;
  }
`;

export const SlideWrapper = styled.div`
  display: none;
  &.active {
    display: flex;
    -webkit-animation: ${slideLeft} 0.5s;
    animation: ${slideLeft} 0.5s;
  }
`;
