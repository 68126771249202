// components
import { Container } from 'components/Grid';
import { MirandaGradientButton } from '@/components/Button';

// styles
import { PageWrapper, Title, Paragraph } from './BackgroundImageSection.styles';

export const BackgroundImageSection = ({ content, variant }) => {
  return (
    <PageWrapper
      bg={content.image}
      className={content.biggerBg ? 'biggerBg' : ''}
    >
      <Container>
        <Title className={content.biggerBg ? 'biggerBg' : ''}>
          {content.title}
        </Title>
        {content.description ? (
          <Paragraph className={variant === 'small-text' ? 'small' : ''}>
            {content.description}
          </Paragraph>
        ) : (
          ''
        )}
        <MirandaGradientButton
          as="a"
          href={content.CTA.link}
          target={content.CTA.external ? `_blank` : ``}
          rel={content.CTA.external ? `noreferrer noopener` : ``}
        >
          <span>{content.CTA.label}</span>
        </MirandaGradientButton>
      </Container>
    </PageWrapper>
  );
};
