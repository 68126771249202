import styled from 'styled-components';

export const PageWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
`;

export const MainContent = styled.main`
  text-align: center;
`;

export const HubspotFormWrapper = styled.section``;
