//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '@/containers-m/Navigation';

import {
  HubspotForm,
  HubspotFormTitle,
  HubspotFormDescription,
} from '@/containers/HubspotForm';
import { defaultOptions } from '@/vendor/HubspotFormIntegration';

// context containers
import { HeroSection } from './containers/index';

//styles
import {
  PageWrapper,
  AlignedSection,
  Title,
  CardGroup,
  Card,
  Description,
  Text,
  ContainerWithBorder,
  CardSideBySide,
  SimpleText,
  Link,
} from './Contact.styles';
import { Container } from '@/components/Grid/Container';

//assets
import { OFFICES } from '@/datafiles/offices/address';

const Contact = () => {
  return (
    <>
      <PageWrapper>
        <Navigation />
        <>
          <HeroSection />
          <AlignedSection>
            <Container>
              <Title>Our office</Title>
              <CardGroup>
                <Card>
                  <Description>{OFFICES.main.city}</Description>
                  <Text> {OFFICES.main.address}</Text>
                  <Text>
                    {`${OFFICES.main.zipcode} ${OFFICES.main.city}
                                      , ${OFFICES.main.state}`}
                  </Text>
                </Card>
              </CardGroup>
            </Container>
          </AlignedSection>
          <AlignedSection>
            <ContainerWithBorder>
              <Title>Need assistance?</Title>
              <CardGroup>
                <CardSideBySide>
                  <Description>Carrier Sales</Description>
                  <Text>carrier@loadsmart.com</Text>
                  <Text>(646) 887 6278</Text>
                </CardSideBySide>
                <CardSideBySide>
                  <Description>Shipper Sales</Description>
                  <Text>sales@loadsmart.com</Text>
                  <Text>(646) 887 6278</Text>
                </CardSideBySide>
              </CardGroup>
            </ContainerWithBorder>
          </AlignedSection>

          <AlignedSection>
            <ContainerWithBorder>
              <Title>Looking for a job opportunity?</Title>
              <SimpleText>
                Just take a look at our{' '}
                <Link href="/careers/">Careers Page!</Link> There you will see
                all our currently open positions.
              </SimpleText>
            </ContainerWithBorder>
          </AlignedSection>

          <HubspotForm {...defaultOptions}>
            <HubspotFormTitle>
              Want to send us a message right away?
            </HubspotFormTitle>
            <HubspotFormDescription>
              Fill out the form below and a member of our team will be in touch
              shortly.
            </HubspotFormDescription>
          </HubspotForm>
        </>
        <Footer />
      </PageWrapper>
    </>
  );
};

export default Contact;
export { Contact };
