/* eslint-disable @next/next/no-img-element */
import React, { useState, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';

//helpers
import { sleep } from '@/helpers/sleep';

// components
import { Container } from 'components/Grid';

// styles
import {
  PropertiesCarouselWrapper,
  ContentNavigation,
  ContentWrapper,
  Subtitle,
  Content,
  Link,
  SlidesSection,
  SlidesRotator,
  SlidesContainer,
  SlideBar,
  SystemImage,
} from './Carousel.styles';

// slides
import { Slide } from './Slide';

const properties = [
  {
    name: 'loadmanagement',
    active: true,
    subtitle: (
      <>
        Effortless Load
        <br /> Management
      </>
    ),
    content: `Easily manage and see details about your trucks, trailers, and drivers within Carrier TMS or on our Loads App! Upload new information whenever you need.`,
    link: {
      url: 'https://loadsmart.com/carrier/tms/features/#dispatching-vehicle-tracking',
      external: true,
      label: `Start Managing Now!`,
    },
    image: {
      src: `/images/carrier-tms/slides/Slide-1.png`,
      alt: 'Carrier TMS, Loads screen',
    },
  },
  {
    name: 'emptymiles',
    subtitle: (
      <>
        Multi-View Load Board <br />
        to Reduce Empty Miles
      </>
    ),
    content: `A consolidated multi-load board view allows you to find better loads, see suggested loads based on where your trucks will be empty, and view dispatcher performance reporting.`,
    link: {
      url: 'https://carrier.loadsmart.com/#/signup',
      external: true,
      label: `Check out the Loadboard`,
    },
    image: {
      src: `/images/carrier-tms/slides/Slide-2.png`,
      alt: 'Carrier TMS, Truckboard screen',
    },
  },
  {
    name: 'safetycompilance',
    subtitle: (
      <>
        Concise Reporting
        <br /> & Quick Invoicing
      </>
    ),
    content: `Receive easy-to-understand reports around your organization's performance. Create and send invoices within the same platform in seconds.`,
    link: {
      url: 'https://tms.kamion.io/login?state=checkAccount&__hstc=32328097.74da73e6aea128bbf7b33d90935d2139.1631884434263.1677597267980.1677600269152.372&__hssc=32328097.1.1677600269152&__hsfp=1404585697',
      external: true,
      label: `Start for Free`,
    },
    image: {
      src: `/images/carrier-tms/slides/Slide-3.png`,
      alt: 'Carrier TMS, Reports screen',
    },
  },
  {
    name: 'manageaccounts',
    subtitle: (
      <>
        Access Quality Loads
        <br /> from Reliable Shippers
      </>
    ),
    content: `Save Preferred Lanes and get access to historical lane data, so you can get fast personalized load options for the lanes you move the most!`,
    link: {
      url: 'https://carrier.loadsmart.com/#/signup',
      external: true,
      label: `Start Finding Loads`,
    },
    image: {
      src: `/images/carrier-tms/slides/Slide-4.png`,
      alt: 'Carrier TMS, Load search screen',
    },
  },
];

let timeout = null;
const interval = 5000;

export function Carousel() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [reverse, setReverse] = useState(false);
  const time = 1;
  const timeloop = `${time}s`;
  const router = useRouter();

  const clearTimer = () => {
    window.clearInterval(timeout);
  };

  const changeSlide = useCallback(() => {
    setReverse(false);
    setActiveIndex((activeIndex) => (activeIndex + 1) % properties.length);
  }, []);

  const updateActive = useCallback(
    (current) => {
      if (current !== activeIndex) {
        sleep(time * 400).then(() => {
          clearTimer();
          setReverse(true);
          setActiveIndex(current);
          timeout = window.setInterval(() => {
            changeSlide();
          }, interval);
        });
      }

      return false;
    },
    [activeIndex, changeSlide]
  );

  const testAfter = (val) => {
    if (
      val === activeIndex + 1 ||
      (0 === val && activeIndex + 1 === properties.length)
    ) {
      return true;
    }
    return false;
  };

  const testBefore = (val) => {
    if (
      val === activeIndex - 1 ||
      (0 === activeIndex && val + 1 === properties.length)
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    timeout = window.setInterval(() => {
      changeSlide();
    }, interval);

    return () => {
      clearTimer();
    };
  }, [changeSlide]);

  return (
    <PropertiesCarouselWrapper>
      {/* slider content */}
      <SlidesSection>
        <SlidesContainer>
          {properties.map((item, key) => {
            const indexkey = `slide-${item.name}-${key}`;
            const isvisible = key === activeIndex;
            const isAfter = testAfter(key);
            const isBefore = testBefore(key);

            return (
              <SlidesRotator
                key={indexkey}
                activeIndex={activeIndex}
                className={classnames({
                  active: isvisible,
                  inactive: !isvisible,
                  after: isAfter,
                  before: isBefore,
                  reverse: reverse,
                })}
                time={timeloop}
              >
                <Slide property={item} />
              </SlidesRotator>
            );
          })}
        </SlidesContainer>
      </SlidesSection>

      <Container>
        {/* navigation: content */}
        <SlideBar
          style={
            activeIndex > 0 ? { marginLeft: activeIndex * 25.5 + '%' } : {}
          }
        />
        <ContentNavigation>
          {properties.map((item, key) => {
            const indexkey = `content-${item.name}-${key}`;
            const isvisible = key === activeIndex;
            return (
              <ContentWrapper
                key={indexkey}
                className={classnames({
                  active: isvisible,
                  inactive: !isvisible,
                })}
                onClick={() => {
                  updateActive(key);
                }}
                aria-label={`${item.name} slide`}
              >
                {item.image && (
                  <SystemImage>
                    <img
                      src={`${item.image.src}`}
                      alt={item.image.alt}
                      width="786"
                      height=""
                    />
                  </SystemImage>
                )}

                <Subtitle>{item.subtitle}</Subtitle>
                <Content>{item.content}</Content>
                <Link
                  href={
                    item.link.external
                      ? item.link.url
                      : `${router.asPath}` + item.link.url
                  }
                  target={item.link.external ? `_blank` : `_self`}
                  rel={item.link.external ? `noreferrer noopener` : ``}
                >
                  {item.link.label}
                </Link>
              </ContentWrapper>
            );
          })}
        </ContentNavigation>
      </Container>
    </PropertiesCarouselWrapper>
  );
}
