import styled, { css } from 'styled-components';

//helpers
import { screen } from '@/helpers/screen';

//components
import { UpperButton } from '@/components/Button';

//containers
import { Hero } from '@/containers/Hero';
import { Title, Description } from '@/containers/Hero/Hero.styles';

const responsiveBackground = {
  sm: `url(/images/contact/small_hero.png)`,
  md: `url(/images/contact/medium_hero.png)`,
  lg: `url(/images/contact/large_hero.png)`,
  xxlg: `url(/images/contact/xxlarge_hero.png)`,
};

const ctaStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 0;
  width: 100%;

  ${screen.md} {
    width: 158px;
  }

  ${screen.lg} {
    width: 227px;
  }

  ${screen.xxlg} {
    width: 300px;
  }
`;

const HeroBackground = styled(Hero)`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ${(props) => props.image?.sm && `background-image: ${props.image?.sm}`};

  ${screen.md} {
    ${(props) => props.image?.md && `background-image: ${props.image?.md}`};
  }

  ${screen.lg} {
    ${(props) => props.image?.lg && `background-image: ${props.image?.lg}`};
  }

  ${screen.xxlg} {
    ${(props) => props.image?.xxlg && `background-image: ${props.image?.xxlg}`};
  }
`;

export const HeroSection = () => {
  return (
    <HeroBackground image={responsiveBackground}>
      <Title>Contact us</Title>
      <Description>
        Want to get in touch? We
        {"'"}d love to hear from you. Here
        {"'"}s how you can reach us...
      </Description>
      <UpperButton as="a" href="#hubspot-form" css={[ctaStyle]}>
        Contact Us
      </UpperButton>
    </HeroBackground>
  );
};
