import React, { useState, useEffect, useCallback } from 'react';
import classnames from 'classnames';

// components
import { Container } from 'components/Grid';

// styles
import {
  PropertiesCarouselWrapper,
  TitleSlider,
  Title,
  LogosNavigation,
  LogoItem,
  BulletNavigation,
  BulletItem,
  SlidesList,
  SlideWrapper,
} from './PropertiesCarousel.styles';

// logos
import {
  LogoLinkOpendock,
  LogoLinkShipperGuide,
  LogoLinkCarrierTMS,
} from './LogoLink';

// slides
import { Slide } from './Slide';

const properties = [
  {
    name: 'carrierTMS',
    active: true,
    keyword: 'fleet',
    color: '#0CA933',
    logo: <LogoLinkCarrierTMS />,
    subtitle: (
      <>
        Optimize dispatch. Reduce overhead costs. <br /> Scale fleets.
      </>
    ),
    content: `Carrier TMS is a truck management software to help dispatchers optimize driver’s time on the
                road. All your carrier operations in one place: load boards, accounting, billing, safety
                checks, fleet visibility, tracking, reporting, and more, all brought together to operate
                efficiently and profitably.`,
    link: {
      url: '/carrier/tms/',
      label: `Optimize your fleet management now`,
    },
    image: {
      src: `/images/home/screens/system-screen-shot-kamion.png`,
      alt: 'Carrier TMS System Screenshot',
    },
  },
  {
    name: 'shipperguide',
    keyword: 'freight procurement',
    color: '#29d454',
    logo: <LogoLinkShipperGuide />,
    subtitle: `Streamline your freight procurement process.`,
    content: `Price 1000 lanes in 1 minute. Match your contracted lanes with the best available
                carrier in your network. Get instant short-term contract rates and easily run mini-bids
                by consolidating the process into a single easy to use tool that streamlines all the
                communication.`,
    link: {
      url: '/shipper/shipperguide/',
      label: `Optimize freight procurement now`,
    },
    image: {
      src: `/images/home/screens/system-screen-shot-shipperguide.png`,
      alt: 'ShipperGuide TMS System Screenshot',
    },
  },
  {
    name: 'opendock',
    keyword: 'warehouse',
    color: '#0CA933',
    logo: <LogoLinkOpendock />,
    subtitle: `Automate dock scheduling for your warehouses.`,
    content: `Opendock is a dock scheduling software to help facilities eliminate phone calls, e-mails
                and spreadsheets by letting carriers book appointments online. A simple setup and easy
                to use tool to manage your docks, including: e-mail notifications, dwell time tracking,
                carrier on-time scorecards, and online scheduling.`,
    link: {
      url: 'https://opendock.com/',
      label: `Optimize your warehouse now`,
    },
    image: {
      src: `/images/home/screens/system-screen-shot-opendock.png`,
      alt: 'Opendock System Screenshot',
    },
  },
];

let timeout = null;
const interval = 10000;

export function PropertiesCarousel() {
  const [activeIndex, setActiveIndex] = useState(0);

  const clearTimer = () => {
    window.clearInterval(timeout);
  };

  const changeSlide = useCallback(() => {
    setActiveIndex((activeIndex) => (activeIndex + 1) % properties.length);
  }, []);

  const updateActive = useCallback(
    (current) => {
      if (current !== activeIndex) {
        clearTimer();
        setActiveIndex(current);
        timeout = window.setInterval(() => {
          changeSlide();
        }, interval);
      }

      return false;
    },
    [activeIndex, changeSlide]
  );

  useEffect(() => {
    timeout = window.setInterval(() => {
      changeSlide();
    }, interval);

    return () => {
      clearTimer();
    };
  }, [changeSlide]);

  return (
    <PropertiesCarouselWrapper>
      <Container>
        <TitleSlider>
          {properties.map((item, key) => {
            const indexkey = `title-${item.name}`;
            const isvisible = key === activeIndex;
            return (
              <Title
                key={indexkey}
                className={classnames({
                  active: isvisible,
                  inactive: !isvisible,
                })}
                color={item.color}
              >
                Your
                <strong> {item.keyword} </strong>
                powered by Loadsmart
              </Title>
            );
          })}
        </TitleSlider>

        {/* navigation: logos */}
        <LogosNavigation>
          {properties.map((item, key) => {
            const indexkey = `logo-${item.name}`;
            const isvisible = key === activeIndex;
            return (
              <LogoItem
                key={indexkey}
                className={classnames({
                  [`${item.name}`]: true,
                  active: isvisible,
                  inactive: !isvisible,
                })}
                onClick={() => {
                  updateActive(key);
                }}
              >
                {item.logo}
              </LogoItem>
            );
          })}
        </LogosNavigation>

        {/* slider content */}
        <SlidesList>
          {properties.map((item, key) => {
            const indexkey = `slide-${item.name}`;
            const isvisible = key === activeIndex;
            return (
              <SlideWrapper
                key={indexkey}
                className={classnames({
                  active: isvisible,
                  inactive: !isvisible,
                })}
              >
                <Slide property={item} />
              </SlideWrapper>
            );
          })}
        </SlidesList>

        {/* navigation: bullets */}
        <BulletNavigation>
          {properties.map((item, key) => {
            const indexkey = `bullet-${item.name}`;
            const isvisible = key === activeIndex;
            return (
              <BulletItem
                key={indexkey}
                className={classnames({
                  active: isvisible,
                  inactive: !isvisible,
                })}
                onClick={() => {
                  updateActive(key);
                }}
                aria-label={`${item.name} slide`}
              >
                <span aria-hidden="true">{item.name}</span>
              </BulletItem>
            );
          })}
        </BulletNavigation>
      </Container>
    </PropertiesCarouselWrapper>
  );
}
