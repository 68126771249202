export function LogoLinkCarrierTMS() {
  return (
    <svg
      height="67"
      viewBox="0 0 235 34.02"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1=".25"
          y1="33.7"
          x2="33.7"
          y2=".25"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".15" stopColor="#00d7d7" />
          <stop offset="1" stopColor="#84f11e" />
        </linearGradient>
      </defs>
      <path
        d="M22.19,11.34H13.23V0h19.82c.53,0,.97,.43,.97,.95V20.79h-11.34V11.82c0-.26-.22-.48-.48-.48Zm-10.37,11.34c-.27,0-.48-.21-.48-.48V0L.28,10.93h0C.11,11.1,0,11.34,0,11.61h0v21.46H0c0,.53,.43,.95,.97,.95H22.28c.27,0,.51-.11,.68-.28l11.06-11.06H11.82Z"
        fill="url(#linear-gradient)"
      />
      <path
        d="M43.1,17c0-6.6,4.93-11.63,11.59-11.63,4.37,0,8.06,2.23,10.08,5.58l-4.74,3.01c-1.02-2.01-2.82-3.29-5.3-3.29-3.6,0-6.11,2.82-6.11,6.32s2.45,6.26,6.05,6.26c2.7,0,4.56-1.55,5.49-3.69l4.99,2.67c-1.92,3.75-5.61,6.39-10.45,6.39-6.97,0-11.59-5.18-11.59-11.62Zm29.73-11.32h5.21l7.75,22.63h-5.49l-1.09-3.32h-7.59l-1.08,3.32h-5.46l7.75-22.63Zm.43,14.38h4.34l-2.17-6.6-2.17,6.6Zm14.2-14.38h7.66c5.46,0,8.52,3.5,8.52,7.78,0,2.91-1.3,5.61-4.06,6.88l4.71,7.97h-6.11l-5.33-9.73v9.73h-5.39V5.69Zm5.39,4.96v5.95h1.86c2.29,0,3.44-1.49,3.44-3.07,0-1.86-1.18-2.88-3.44-2.88h-1.86Zm13.02-4.96h7.66c5.46,0,8.52,3.5,8.52,7.78,0,2.91-1.3,5.61-4.06,6.88l4.71,7.97h-6.11l-5.33-9.73v9.73h-5.39V5.69Zm5.39,4.96v5.95h1.86c2.29,0,3.44-1.49,3.44-3.07,0-1.86-1.18-2.88-3.44-2.88h-1.86Zm13.11-4.96h5.46V28.32h-5.46V5.69Zm7.84,0h13.42v5.02h-8.03v3.72h8.03v5.02h-8.03v3.84h8.03v5.02h-13.42V5.69Zm15.62,0h7.66c5.46,0,8.52,3.5,8.52,7.78,0,2.91-1.3,5.61-4.06,6.88l4.71,7.97h-6.11l-5.33-9.73v9.73h-5.39V5.69Zm5.39,4.96v5.95h1.86c2.29,0,3.44-1.49,3.44-3.07,0-1.86-1.18-2.88-3.44-2.88h-1.86Zm26.72,.09h-5.64V5.69h16.49v5.05h-5.58V28.32h-5.27V10.74Zm12.93-5.05h5.43l5.73,7.94,5.95-7.94h5.12V28.32h-5.39V14.37l-5.7,7.69-5.73-7.72v13.98h-5.39V5.69Zm23.62,18.85l4.74-3.72c1.05,1.77,2.67,2.76,4.34,2.76s2.67-1.05,2.67-2.17c0-1.4-1.98-1.83-4.18-2.48-2.98-.87-6.48-2.14-6.48-6.63,0-3.94,3.22-6.94,7.87-6.94,4,0,6.32,1.64,8.06,3.6l-4.34,3.32c-.84-1.36-2.17-2.2-3.69-2.2s-2.45,.84-2.45,1.89c0,1.36,1.95,1.83,4.18,2.51,3.04,.93,6.63,2.29,6.63,6.79,0,3.88-3.01,7.41-8.28,7.41-4.53,0-7.1-1.92-9.08-4.12Z"
        fill="#333d47"
      />
    </svg>
  );
}

export function LogoLinkShipperGuide() {
  return (
    <svg height="67" viewBox="0 0 255 34.02" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient
          id="linear-gradient"
          x1=".25"
          y1="33.7"
          x2="33.7"
          y2=".25"
          gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".15" stopColor="#00d7d7" />
          <stop offset="1" stopColor="#84f11e" />
        </linearGradient>
      </defs>
      <path
        d="M22.19,11.34H13.23V0h19.82c.53,0,.97,.43,.97,.95V20.79h-11.34V11.82c0-.26-.22-.48-.48-.48Zm-10.37,11.34c-.27,0-.48-.21-.48-.48V0L.28,10.93h0C.11,11.1,0,11.34,0,11.61h0v21.46H0c0,.53,.43,.95,.97,.95H22.28c.27,0,.51-.11,.68-.28l11.06-11.06H11.82Z"
        fill="url(#linear-gradient)"
      />
      <g>
        <path
          d="M42.61,24.56l4.76-3.73c1.06,1.77,2.67,2.77,4.35,2.77s2.67-1.06,2.67-2.18c0-1.4-1.99-1.83-4.2-2.49-2.98-.87-6.5-2.14-6.5-6.65,0-3.95,3.23-6.96,7.9-6.96,4.01,0,6.34,1.65,8.08,3.61l-4.35,3.33c-.84-1.37-2.18-2.21-3.7-2.21s-2.46,.84-2.46,1.9c0,1.37,1.96,1.83,4.2,2.52,3.05,.93,6.65,2.3,6.65,6.81,0,3.89-3.01,7.43-8.3,7.43-4.54,0-7.12-1.93-9.11-4.13Z"
          fill="#333d47"
        />
        <path
          d="M74,19.61h-6.53v8.73h-5.44V5.66h5.44V14.27h6.53V5.66h5.41V28.35h-5.41v-8.73Z"
          fill="#333d47;"
        />
        <path d="M81.82,5.66h5.47V28.35h-5.47V5.66Z" fill="#333d47;" />
        <path
          d="M89.68,5.66h7.74c5.32,0,8.7,3.76,8.7,8.3s-3.36,8.42-8.73,8.42h-2.27v5.97h-5.44V5.66Zm5.44,4.94v6.75h2.08c1.96,0,3.42-1.34,3.42-3.36s-1.46-3.39-3.42-3.39h-2.08Z"
          fill="#333d47;"
        />
        <path
          d="M108.21,5.66h7.74c5.32,0,8.7,3.76,8.7,8.3s-3.36,8.42-8.73,8.42h-2.27v5.97h-5.44V5.66Zm5.44,4.94v6.75h2.08c1.96,0,3.42-1.34,3.42-3.36s-1.46-3.39-3.42-3.39h-2.08Z"
          fill="#333d47;"
        />
        <path
          d="M126.73,5.66h13.46v5.04h-8.05v3.73h8.05v5.04h-8.05v3.85h8.05v5.04h-13.46V5.66Z"
          fill="#333d47;"
        />
        <path
          d="M142.39,5.66h7.68c5.47,0,8.55,3.51,8.55,7.8,0,2.92-1.31,5.63-4.07,6.9l4.72,7.99h-6.12l-5.35-9.76v9.76h-5.41V5.66Zm5.41,4.97v5.97h1.86c2.3,0,3.45-1.49,3.45-3.08,0-1.87-1.18-2.89-3.45-2.89h-1.86Z"
          fill="#333d47;"
        />
      </g>
      <g>
        <path
          d="M161.58,17c0-6.43,4.97-11.66,11.87-11.66,4.38,0,8.08,2.33,10.07,5.91l-2.11,1.21c-1.52-2.89-4.38-4.82-7.96-4.82-5.69,0-9.39,4.23-9.39,9.36s3.88,9.36,9.26,9.36,8.36-3.39,8.92-7.8h-9.11v-2.3h11.62v.68c0,6.25-4.07,11.72-11.44,11.72-6.59,0-11.75-5.22-11.75-11.66Z"
          fill="#333d47;"
        />
        <path
          d="M186.54,20.95V5.66h2.42v15.42c0,2.89,2.27,5.28,5.28,5.28s5.31-2.39,5.31-5.28V5.66h2.42v15.29c0,4.35-3.29,7.71-7.74,7.71s-7.71-3.36-7.71-7.71Z"
          fill="#333d47;"
        />
        <path d="M204.75,5.66h2.42V28.35h-2.42V5.66Z" fill="#333d47;" />
        <path
          d="M210.06,5.66h6.34c7.06,0,11.81,4.91,11.81,11.35s-5.04,11.34-11.72,11.34h-6.43V5.66Zm2.42,2.24V26.08h3.82c5.5,0,9.42-3.92,9.42-9.08s-3.67-9.11-9.51-9.11h-3.73Z"
          fill="#333d47;"
        />
        <path
          d="M230.14,5.66h13.77v2.3h-11.34v7.86h11.34v2.3h-11.34v7.89h11.34v2.33h-13.77V5.66Z"
          fill="#333d47;"
        />
      </g>
      <g>
        <path
          d="M247.88,2.42h-1.07v-.53h2.7v.53h-1.07v3.25h-.56V2.42Z"
          fill="#333d47;"
        />
        <path
          d="M249.99,1.89h.56l1.28,1.77,1.32-1.77h.53v3.78h-.56V2.79l-1.31,1.76-1.27-1.72v2.83h-.56V1.89Z"
          fill="#333d47;"
        />
      </g>
    </svg>
  );
}

export function LogoLinkOpendock() {
  return (
    <svg height="67" viewBox="0 0 235 34.02" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient
          id="linear-gradient"
          x1=".25"
          y1="33.7"
          x2="33.7"
          y2=".25"
          gradientTransform="matrix(1, 0, 0, 1, 0, 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".15" stopColor="#00d7d7" />
          <stop offset="1" stopColor="#84f11e" />
        </linearGradient>
      </defs>
      <path
        d="M22.19,11.34H13.23V0h19.82c.53,0,.97,.43,.97,.95V20.79h-11.34V11.82c0-.26-.22-.48-.48-.48Zm-10.37,11.34c-.27,0-.48-.21-.48-.48V0L.28,10.93h0C.11,11.1,0,11.34,0,11.61h0v21.46H0c0,.53,.43,.95,.97,.95H22.28c.27,0,.51-.11,.68-.28l11.06-11.06H11.82Z"
        fill="url(#linear-gradient)"
      />
      <g>
        <path
          d="M41.56,17c0-6.43,5.13-11.66,11.59-11.66s11.62,5.22,11.62,11.66-5.1,11.66-11.62,11.66-11.59-5.19-11.59-11.66Zm17.78,0c0-3.48-2.67-6.34-6.19-6.34s-6.12,2.86-6.12,6.34,2.58,6.31,6.12,6.31,6.19-2.86,6.19-6.31Z"
          fill="#333d47"
        />
        <path
          d="M66.76,5.66h7.74c5.32,0,8.7,3.76,8.7,8.3s-3.36,8.42-8.73,8.42h-2.27v5.97h-5.44V5.66Zm5.44,4.94v6.75h2.08c1.96,0,3.42-1.34,3.42-3.36s-1.46-3.39-3.42-3.39h-2.08Z"
          fill="#333d47"
        />
        <path
          d="M85.29,5.66h13.46v5.04h-8.05v3.73h8.05v5.04h-8.05v3.85h8.05v5.04h-13.46V5.66Z"
          fill="#333d47"
        />
        <path
          d="M100.95,5.66h5.78l8.21,12.93V5.66h5.38V28.35h-5.38l-8.58-13.58v13.58h-5.41V5.66Z"
          fill="#333d47"
        />
        <path
          d="M122.64,5.66h6.81c7.06,0,11.81,4.91,11.81,11.35s-4.97,11.34-11.72,11.34h-6.9V5.66Zm5.41,5.04v12.59h1.24c3.98,0,6.5-2.77,6.5-6.28s-2.33-6.31-6.59-6.31h-1.15Z"
          fill="#333d47"
        />
        <path
          d="M143.05,17c0-6.43,5.13-11.66,11.59-11.66s11.62,5.22,11.62,11.66-5.1,11.66-11.62,11.66-11.59-5.19-11.59-11.66Zm17.78,0c0-3.48-2.67-6.34-6.19-6.34s-6.12,2.86-6.12,6.34,2.58,6.31,6.12,6.31,6.19-2.86,6.19-6.31Z"
          fill="#333d47"
        />
        <path
          d="M168.07,17c0-6.62,4.94-11.66,11.62-11.66,4.38,0,8.08,2.24,10.1,5.59l-4.75,3.02c-1.03-2.02-2.83-3.29-5.32-3.29-3.61,0-6.12,2.83-6.12,6.34s2.46,6.28,6.06,6.28c2.71,0,4.57-1.55,5.5-3.7l5,2.67c-1.93,3.76-5.63,6.4-10.47,6.4-6.99,0-11.62-5.19-11.62-11.66Z"
          fill="#333d47"
        />
        <path
          d="M199.02,18.62l-1.83,2.46v7.27h-5.44V5.66h5.44V13.83l6.09-8.17h6.4l-6.78,8.92,7.3,13.77h-6.06l-5.13-9.73Z"
          fill="#333d47"
        />
      </g>
      <g>
        <path
          d="M212.67,2.42h-1.07v-.53h2.7v.53h-1.07v3.25h-.56V2.42Z"
          fill="#333d47"
        />
        <path
          d="M214.78,1.89h.56l1.28,1.77,1.32-1.77h.53v3.78h-.56V2.79l-1.31,1.76-1.27-1.72v2.83h-.56V1.89Z"
          fill="#333d47"
        />
      </g>
    </svg>
  );
}
