import styled from 'styled-components';
import { Section, Container } from '@/components/Grid';
import { screen } from '@/helpers/screen';
import { FlexColumn, FlexRow } from '@/components/Grid/Flex';

export const PageWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
`;

export const AlignedSection = styled(Section)`
  text-align: center;

  ${screen.md} {
    text-align: left;
  }
`;

export const ContainerWithBorder = styled(Container)`
  padding-top: 50px;

  border-top: 1px solid #d3d9d9;

  ${screen.md} {
    padding-top: 60px;
  }

  ${screen.lg} {
    padding-top: 80px;
  }

  ${screen.xxlg} {
    padding-top: 100px;
  }
`;

export const Title = styled.h2`
  margin-bottom: 30px;
  margin-top: 0px;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;

  ${screen.md} {
    margin-bottom: 40px;

    font-size: 28px;
    line-height: 40px;
  }

  ${screen.lg} {
    margin-bottom: 58px;

    font-size: 38px;
    line-height: 50px;
  }

  ${screen.xxlg} {
    margin-bottom: 93px;

    font-size: 42px;
    line-height: 52px;
  }
`;

export const Card = styled(FlexColumn)`
  align-items: center;
  justify-content: center;
  margin: 30px 0;

  ${screen.md} {
    align-items: flex-start;
  }
`;

export const CardSideBySide = styled(Card)`
  ${screen.md} {
    width: 50%;
  }
`;

export const CardGroup = styled(FlexRow)`
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: -30px 0;

  ${screen.md} {
    justify-content: flex-start;
    margin: 0 -7px;
  }

  ${screen.lg} {
    margin: 0 -8px;
  }

  ${screen.xxlg} {
    margin: 0 -10px;
  }
`;

export const Description = styled.h3`
  margin: 0px;
  font-weight: bold;
  font-size: 19px;
  line-height: 30px;

  ${screen.md} {
    font-size: 21px;
    line-height: 34px;
  }

  ${screen.lg} {
    font-size: 28px;
    line-height: 38px;
  }

  ${screen.xxlg} {
    font-size: 32px;
    line-height: 42px;
  }
`;

export const Text = styled.p`
  margin: 0px;
  font-size: 19px;
  line-height: 30px;

  ${screen.lg} {
    font-size: 21px;
    line-height: 34px;
  }

  ${screen.xxlg} {
    font-size: 28px;
    line-height: 38px;
  }
`;

export const SimpleText = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;

  ${screen.md} {
    margin-bottom: 43px;
  }

  ${screen.lg} {
    margin-bottom: 61px;

    font-size: 16px;
    line-height: 30px;
  }

  ${screen.xxlg} {
    margin-bottom: 92px;

    font-size: 20px;
    line-height: 38px;
  }
`;

export const Link = styled.a`
  ${SimpleText} color: black;
  text-decoration: underline;
  font-weight: bold;

  cursor: pointer;

  &:hover {
    color: #6c8694;
  }
`;
