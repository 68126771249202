import styled from 'styled-components';
import { screen } from '@/helpers/screen';

export const SlideWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;

  ${screen.md} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const SlidePanel = styled.article`
  width: 100%;
  padding: 20px 0;

  text-align: center;

  ${screen.md} {
    width: 50%;
    padding: 40px 0 20px;

    text-align: left;
  }

  ${screen.lg} {
    padding: 40px 0;
  }
`;

export const Content = styled.p`
  margin: 16px 0;

  color: ${({ theme }) => theme.color.neutral.darkest};
  font-size: 12px;
  line-height: 22px;

  ${screen.md} {
    margin: 28px 0;
  }

  ${screen.lg} {
    margin: 36px 0;

    font-size: 16px;
    line-height: 30px;
  }
  ${screen.xxlg} {
    margin: 42px 0;

    font-size: 20px;
    line-height: 38px;
  }
`;

export const Subtitle = styled.h2`
  color: #787b7d;
  font-size: 16px;
  line-height: 22px;

  br {
    display: none;
  }

  ${screen.md} {
    line-height: 26px;
  }
  ${screen.lg} {
    font-size: 22px;
    line-height: 36px;

    br {
      display: inline-block;
    }
  }
  ${screen.xxlg} {
    font-size: 28px;
    line-height: 44px;
  }
`;

export const Link = styled.a`
  color: ${(props) => props.color || `inherit`};
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;

  ${screen.md} {
    font-size: 15px;
    line-height: 18px;
  }
  ${screen.lg} {
    font-size: 20px;
    line-height: 23px;
  }
  ${screen.xxlg} {
    font-size: 24px;
    line-height: 28px;
  }
`;

export const SystemImage = styled.figure`
  position: relative;

  min-width: 100%;
  height: auto;
  margin: 20px 0 0;

  img {
    width: 321px;
    height: auto;
  }

  ${screen.md} {
    min-width: 50%;
    min-height: 310px;

    img {
      position: absolute;
      top: 30px;
      left: 0;

      width: 379px;
    }
  }
  ${screen.lg} {
    min-height: 380px;

    img {
      width: 556px;
    }
  }
  ${screen.xxlg} {
    min-height: 500px;

    img {
      width: 785.9px;
    }
  }
`;
