import styled from 'styled-components';
import { screen } from '@/helpers/screen';

export const Container = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 20px;

  ${screen.md} {
    padding: 0;
  }
`;

export const SquaresMenuWrapper = styled.section`
  padding: 20px 0 40px;

  text-align: center;

  background: linear-gradient(91.13deg, #eff4f5 10.93%, #e7edef 94.82%);

  ${screen.md} {
    padding: 50px 0 0px;
  }
`;

export const Title = styled.h2`
  margin-bottom: 4px;

  color: ${({ theme }) => theme.color.neutral.darkest};
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  text-align: center;

  ${screen.md} {
    margin-bottom: 20px;

    font-size: 28px;
    line-height: 40px;
    br {
      display: none;
    }
  }
  ${screen.lg} {
    font-size: 38px;
    line-height: 50px;
  }

  ${screen.xxlg} {
    margin-bottom: 42px;

    font-size: 48px;
    line-height: 72px;
  }
`;

export const Content = styled.p`
  font-size: 14px;
  line-height: 22px;

  ${screen.md} {
    font-size: 16px;
    line-height: 24px;
  }

  ${screen.lg} {
    font-size: 21px;
    line-height: 34px;
  }

  ${screen.xxlg} {
    font-size: 26px;
    line-height: 38px;

    br {
      display: none;
    }
  }
`;

export const SegmentNavigationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  padding: 20px 0px 0px;

  ${screen.md} {
    padding: 35px 0px 50px;
  }
  ${screen.lg} {
    padding: 55px 0px 50px;
  }
  ${screen.xxlg} {
    padding: 100px 0;
  }
`;

export const NavList = styled.nav`
  display: grid;
  gap: 16px;

  ${screen.md} {
    grid-template-columns: repeat(5, 126px);
    gap: 10px;
  }

  ${screen.lg} {
    grid-template-columns: repeat(5, 182px);
    gap: 14px;
  }

  ${screen.xxlg} {
    grid-template-columns: repeat(5, 256px);
    gap: 20px;
  }
`;

export const ItemMenu = styled.a`
  display: flex;
  flex-direction: column;

  background: #283241;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

  transition: all 0.15s ease-in-out;

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.color.greens.stronger};
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    transform: translateY(-6px);

    transition: all 0.25s ease-in-out;
  }

  &:focus {
    outline: none;
  }
`;

export const Image = styled.picture`
  img,
  source {
    width: 100%;
    height: auto;

    border-radius: 5px 5px 0 0;
    filter: brightness(80%);
  }

  ${screen.md} {
    img,
    source {
      border-radius: 10px 10px 0 0;
    }
  }
`;

export const Label = styled.div`
  margin: auto;

  padding: 2px 0;

  color: ${({ theme }) => theme.color.neutral.lightest};

  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  text-align: center;

  border-radius: 0 0 10px 10px;

  display: none;

  &.mobile {
    display: flex;
  }

  ${screen.md} {
    padding: 6px 4px;

    font-size: 12px;
    line-height: 16.8px;

    &.mobile {
      display: none;
    }
    &:not(.mobile) {
      display: flex;
    }
  }

  ${screen.lg} {
    padding: 24px 4px;

    font-size: 16px;
    line-height: 24px;
  }

  ${screen.xxlg} {
    font-size: 20px;
    line-height: 30px;
  }
`;
